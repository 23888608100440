@use '../../../variables';
@use '../../mixins';

.p-checkbox {
    width: variables.$checkboxWidth;
    height: variables.$checkboxHeight;

    .p-checkbox-box {
        border: variables.$checkboxBorder;
        background: variables.$inputBg;
        width: variables.$checkboxWidth;
        height: variables.$checkboxHeight;
        color: variables.$textColor;
        border-radius: variables.$borderRadius;
        transition: variables.$formElementTransition;

        .p-checkbox-icon {
            transition-duration: variables.$transitionDuration;
            color: variables.$checkboxIconActiveColor;
            font-size: variables.$checkboxIconFontSize;
        }

        .p-icon {
            width: variables.$checkboxIconFontSize;
            height: variables.$checkboxIconFontSize;
        }

        &.p-highlight {
            border-color: variables.$checkboxActiveBorderColor;
            background: variables.$checkboxActiveBg;
        }
    }

    &:not(.p-checkbox-disabled) {
        .p-checkbox-box {
            &:hover {
                border-color: variables.$inputHoverBorderColor;
            }

            &.p-focus {
                @include mixins.focused-input();
            }

            &.p-highlight:hover {
                border-color: variables.$checkboxActiveHoverBorderColor;
                background: variables.$checkboxActiveHoverBg;
                color: variables.$checkboxIconActiveHoverColor;
            }
        }
    }
}

p-checkbox.ng-touched.ng-invalid > .p-checkbox > .p-checkbox-box {
    @include mixins.invalid-input();
}

.p-input-filled {
    .p-checkbox {
        .p-checkbox-box {
            background-color: variables.$inputFilledBg;

            &.p-highlight {
                background: variables.$checkboxActiveBg;
            }
        }

        &:not(.p-checkbox-disabled) {
            .p-checkbox-box {
                &:hover {
                    background-color: variables.$inputFilledHoverBg;
                }

                &.p-highlight:hover {
                    background: variables.$checkboxActiveHoverBg;
                }
            }
        }
    }
}

.p-checkbox-label {
    margin-left: variables.$inlineSpacing;
}

@if (variables.$highlightBg == variables.$checkboxActiveBg) {
    .p-highlight {
        .p-checkbox {
            .p-checkbox-box {
                border-color: variables.$checkboxIconActiveColor;
            }
        }
    }
}
