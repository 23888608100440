@use '../../../variables';

.p-scrolltop {
    width: variables.$scrollTopWidth;
    height: variables.$scrollTopHeight;
    border-radius: variables.$scrollTopBorderRadius;
    box-shadow: variables.$inputOverlayShadow;
    transition: variables.$actionIconTransition;

    &.p-link {
        background: variables.$scrollTopBg;

        &:hover {
            background: variables.$scrollTopHoverBg;
        }
    }

    .p-scrolltop-icon {
        font-size: variables.$scrollTopFontSize;
        color: variables.$scrollTopTextColor;
    }

    .p-icon {
        width: variables.$scrollTopFontSize;
        height: variables.$scrollTopFontSize;
    }
}
