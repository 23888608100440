.sc-table-dropdown {
    width: 100%;

    > .p-dropdown {
        .p-dropdown-label {
            padding-right: 1rem;
            line-height: normal;
        }

        width: 100%;
        background-color: transparent;
        border: none;
    }
}
