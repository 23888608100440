@use 'sass:list';
@use '../../../variables';
@use '../../mixins';

.p-orderlist {
    .p-orderlist-controls {
        padding: variables.$panelContentPadding;

        .p-button {
            margin-bottom: variables.$inlineSpacing;
        }
    }

    .p-orderlist-header {
        background: variables.$panelHeaderBg;
        color: variables.$panelHeaderTextColor;
        border: variables.$panelHeaderBorder;
        padding: variables.$panelHeaderPadding;
        border-bottom: 0 none;
        border-top-right-radius: variables.$borderRadius;
        border-top-left-radius: variables.$borderRadius;

        .p-orderlist-title {
            font-weight: variables.$panelHeaderFontWeight;
        }
    }

    .p-orderlist-filter-container {
        padding: variables.$panelHeaderPadding;
        background: variables.$panelContentBg;
        border: variables.$panelHeaderBorder;
        border-bottom: 0 none;

        .p-orderlist-filter-input {
            padding-right: list.nth(variables.$inputPadding, 2) + variables.$primeIconFontSize;
        }

        .p-orderlist-filter-icon {
            right: list.nth(variables.$inputPadding, 2);
            color: variables.$inputIconColor;
        }
    }

    .p-orderlist-list {
        border: variables.$panelContentBorder;
        background: variables.$panelContentBg;
        color: variables.$panelContentTextColor;
        padding: variables.$inputListPadding;
        border-bottom-right-radius: variables.$borderRadius;
        border-bottom-left-radius: variables.$borderRadius;

        .p-orderlist-item {
            padding: variables.$inputListItemPadding;
            margin: variables.$inputListItemMargin;
            border: variables.$inputListItemBorder;
            color: variables.$inputListItemTextColor;
            background: variables.$inputListItemBg;
            transition: variables.$listItemTransition;

            &:focus {
                @include mixins.focused-listitem();
            }

            &.p-highlight {
                color: variables.$highlightTextColor;
                background: variables.$highlightBg;
            }
        }

        .p-orderlist-empty-message {
            padding: variables.$inputListItemPadding;
            color: variables.$inputListItemTextColor;
        }

        &:not(.cdk-drop-list-dragging) {
            .p-orderlist-item {
                &:not(.p-highlight):hover {
                    background: variables.$inputListItemHoverBg;
                    color: variables.$inputListItemTextHoverColor;
                }
            }
        }
    }

    &.p-orderlist-striped {
        .p-orderlist-list {
            .p-orderlist-item:nth-child(even) {
                background: variables.$panelContentEvenRowBg;

                &:hover {
                    background: variables.$inputListItemHoverBg;
                }
            }
        }
    }
}

.p-orderlist-item {
    &.cdk-drag-preview {
        padding: variables.$inputListItemPadding;
        box-shadow: variables.$inputOverlayShadow;
        border: variables.$inputListItemBorder;
        color: variables.$inputListItemTextColor;
        background: variables.$panelContentBg;
        margin: 0;
    }
}
