@use 'sc-colors' as *;

.p-multiselect.sc-multiselect-link {
    background: none;
    border: none;
    font-weight: bold;
    color: $redColor;

    .p-multiselect-trigger {
        color: $redColor;
        margin-left: -15px;
    }

    .p-multiselect-label.p-placeholder {
        color: $redColor;
    }

    &.p-focus {
        box-shadow: none;
    }

    &:hover {
        border-color: $redColor;
        background-color: $lightRed;
        border-radius: 2px;
    }
}

.p-multiselect-panel {
    .p-multiselect-items-wrapper {
        max-height: none !important;

        .p-multiselect-item.p-highlight {
            background: none;
        }
    }
}
