@use 'sass:list';
@use 'sc-colors' as colors;
@use 'sc-fonts' as fonts;

@use 'primeflex/core/variables' as * with ($fieldMargin: 1.5rem);

@use 'projects/common/styles/theme/sass/theme/variables' as * with ( //global
    $fontFamily: fonts.$fontFamily,
    $textColor: colors.$blackColor,
    $primaryColor: colors.$redColor,
    $primaryTextColor: colors.$whiteColor,
    $errorColor: colors.$redColor,
    $accentColor: #8bc34a,
    $accentTextColor: colors.$whiteColor,
    $highlightTextColor: colors.$blackColor,
    //button
    $buttonHoverBg: colors.$hoverColor,
    $buttonActiveBg: colors.$hoverColor,
    $buttonPadding: 0.714rem 1rem,
    //checkbox
    $checkboxActiveBorderColor: colors.$blackColor,
    $checkboxActiveBg: colors.$blackColor,
    // dialog
    $dialogHeaderPadding: 1.25rem 1.5rem,
    //radiobutton
    $radiobuttonActiveBorderColor: colors.$blackColor,
    $radiobuttonIconActiveColor: colors.$blackColor,
    //datatable
    $tableHeaderBorder: 1px solid colors.$grey3Color,
    $tableHeaderBorderWidth: 1px 1px 1px 1px,
    $tableHeaderCellBorder: 1px solid colors.$grey3Color,
    $tableHeaderCellBorderWidth: 1px 1px 1px 1px,
    $tableHeaderCellFontWeight: bold,
    $tableHeaderCellPadding: 1.5rem 1rem,
    $tableHeaderPadding: 1rem 0rem,
    $tableBodyCellBorder: 1px solid colors.$grey3Color,
    $tableBodyCellBorderWidth: 1px 1px 1px 1px,
    $tableBodyCellPadding: 1.5rem 1rem,
    //paginator
    $paginatorPadding: 0.5rem 0rem,
    //input
    $inputTextColor: colors.$blackColor,
    $inputFocusBorderColor: colors.$blackColor,
    $inputHoverBorderColor: colors.$grey1Color,
    $inputBorder: 1px solid colors.$grey3Color,
    //dropdown
    $highlightBg: colors.$grey5Color,
    //progressbar
    $progressBarHeight: 1.5rem,
    //breadcrumb
    $breadcrumbBorder: none,
    $breadcrumbPadding: $spacer 0,
    $breadcrumbBg: none,
    $breadcrumbLastItemTextColor: colors.$grey1Color);

@forward 'projects/common/styles/theme/sass/theme/variables';
@forward 'primeflex/core/variables';

// Common variables
$mainZindex: 0;
$mainPaddingTop: 39.2px;
$mainPaddingBottom: 91px;
$mainBoxShadow: 0px 4px 14px rgba(19, 32, 82, 0.05);
$borderRadius: 2px;
$scFontSizeValue: 14 !default;
$scFontSize: calc($scFontSizeValue / 16) * 100%; // 16 - default font size in browser
$scCardBorder: 1px solid colors.$grey3Color !default;
$inputHeight: 3.14rem;
$mainContentWidth: 85%;
$scHeaderHeight: 5.429rem !default;
$scLogoWidth: 10rem !default;

@mixin sc-toolbar-container {
    background-color: colors.$whiteColor;
    box-shadow: $mainBoxShadow, inset $mainBoxShadow;
    width: 100%;
    margin-top: -($mainPaddingTop);
}