@use '../../../variables';

.p-splitter {
    border: variables.$panelContentBorder;
    background: variables.$panelContentBg;
    border-radius: variables.$borderRadius;
    color: variables.$panelContentTextColor;

    .p-splitter-gutter {
        transition: variables.$actionIconTransition;
        background: variables.$splitterGutterBg;

        .p-splitter-gutter-handle {
            background: variables.$splitterGutterHandleBg;
        }
    }

    .p-splitter-gutter-resizing {
        background: variables.$splitterGutterHandleBg;
    }
}
