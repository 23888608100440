@use 'sass:list';
@use '../../../variables';
@use '../../mixins';

@use 'sass:math';

.p-multiselect {
    background: variables.$inputBg;
    border: variables.$inputBorder;
    transition: variables.$formElementTransition;
    border-radius: variables.$borderRadius;

    &:not(.p-disabled):hover {
        border-color: variables.$inputHoverBorderColor;
    }

    &:not(.p-disabled).p-focus {
        @include mixins.focused-input();
    }

    .p-multiselect-label {
        padding: variables.$inputPadding;
        transition: variables.$formElementTransition;

        &.p-placeholder {
            color: variables.$inputPlaceholderTextColor;
        }
    }

    &.p-multiselect-chip {
        .p-multiselect-label {
            gap: variables.$inlineSpacing;
        }
        .p-multiselect-token {
            padding: math.div(list.nth(variables.$inputPadding, 1), 2) list.nth(variables.$inputPadding, 2);
            background: variables.$highlightBg;
            color: variables.$highlightTextColor;
            border-radius: variables.$borderRadius;

            .p-multiselect-token-icon {
                margin-left: variables.$inlineSpacing;
            }
        }
    }

    .p-multiselect-trigger {
        background: transparent;
        color: variables.$inputIconColor;
        width: variables.$inputGroupAddOnMinWidth;
        border-top-right-radius: variables.$borderRadius;
        border-bottom-right-radius: variables.$borderRadius;
    }
}

.p-inputwrapper-filled {
    .p-multiselect {
        &.p-multiselect-chip {
            .p-multiselect-label {
                padding: math.div(list.nth(variables.$inputPadding, 1), 2) list.nth(variables.$inputPadding, 2);
            }
        }
    }
}

.p-multiselect-panel {
    background: variables.$inputOverlayBg;
    color: variables.$inputListTextColor;
    border: variables.$inputOverlayBorder;
    border-radius: variables.$borderRadius;
    box-shadow: variables.$inputOverlayShadow;

    .p-multiselect-header {
        padding: variables.$inputListHeaderPadding;
        border-bottom: variables.$inputListHeaderBorder;
        color: variables.$inputListHeaderTextColor;
        background: variables.$inputOverlayHeaderBg;
        margin: variables.$inputListHeaderMargin;
        border-top-right-radius: variables.$borderRadius;
        border-top-left-radius: variables.$borderRadius;

        .p-multiselect-filter-container {
            .p-inputtext {
                padding-right: list.nth(variables.$inputPadding, 2) + variables.$primeIconFontSize;
            }

            .p-multiselect-filter-icon {
                right: list.nth(variables.$inputPadding, 2);
                color: variables.$inputIconColor;
            }
        }

        .p-checkbox {
            margin-right: variables.$inlineSpacing;
        }

        .p-multiselect-close {
            margin-left: variables.$inlineSpacing;
            @include mixins.action-icon();
        }
    }

    .p-multiselect-items {
        padding: variables.$inputListPadding;

        .p-multiselect-item {
            margin: variables.$inputListItemMargin;
            padding: variables.$inputListItemPadding;
            border: variables.$inputListItemBorder;
            color: variables.$inputListItemTextColor;
            background: variables.$inputListItemBg;
            transition: variables.$listItemTransition;
            border-radius: variables.$inputListItemBorderRadius;

            &.p-highlight {
                color: variables.$highlightTextColor;
                background: variables.$highlightBg;
            }

            &:not(.p-highlight):not(.p-disabled):hover {
                color: variables.$inputListItemTextHoverColor;
                background: variables.$inputListItemHoverBg;
            }

            &:focus {
                @include mixins.focused-listitem();
            }

            .p-checkbox {
                margin-right: variables.$inlineSpacing;
            }
        }

        .p-multiselect-item-group {
            margin: variables.$submenuHeaderMargin;
            padding: variables.$submenuHeaderPadding;
            color: variables.$submenuHeaderTextColor;
            background: variables.$submenuHeaderBg;
            font-weight: variables.$submenuHeaderFontWeight;
        }

        .p-multiselect-empty-message {
            padding: variables.$inputListItemPadding;
            color: variables.$inputListItemTextColor;
            background: variables.$inputListItemBg;
        }
    }
}

p-multiselect.ng-touched.ng-invalid > .p-multiselect {
    @include mixins.invalid-input();
}

.p-input-filled {
    .p-multiselect {
        background: variables.$inputFilledBg;

        &:not(.p-disabled):hover {
            background-color: variables.$inputFilledHoverBg;
        }

        &:not(.p-disabled).p-focus {
            background-color: variables.$inputFilledFocusBg;
        }
    }
}

p-multiselect.p-multiselect-clearable {
    .p-multiselect-label-container {
        padding-right: list.nth(variables.$inputPadding, 2) + variables.$primeIconFontSize;
    }

    .p-multiselect-clear-icon {
        color: variables.$inputIconColor;
        right: variables.$inputGroupAddOnMinWidth;
    }
}
