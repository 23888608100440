@use '../../../variables';
@use '../../mixins';

.p-button {
    color: variables.$buttonTextColor;
    background: variables.$buttonBg;
    border: variables.$buttonBorder;
    padding: variables.$buttonPadding;
    font-size: variables.$fontSize;
    transition: variables.$formElementTransition;
    border-radius: variables.$borderRadius;

    &:enabled:hover {
        background: variables.$buttonHoverBg;
        color: variables.$buttonTextHoverColor;
        border-color: variables.$buttonHoverBorderColor;
    }

    &:enabled:active {
        background: variables.$buttonActiveBg;
        color: variables.$buttonTextActiveColor;
        border-color: variables.$buttonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: variables.$buttonBg;
        border: variables.$outlinedButtonBorder;

        &:enabled:hover {
            background: rgba(variables.$buttonBg, variables.$textButtonHoverBgOpacity);
            color: variables.$buttonBg;
            border: variables.$outlinedButtonBorder;
        }

        &:enabled:active {
            background: rgba(variables.$buttonBg, variables.$textButtonActiveBgOpacity);
            color: variables.$buttonBg;
            border: variables.$outlinedButtonBorder;
        }

        &.p-button-plain {
            color: variables.$plainButtonTextColor;
            border-color: variables.$plainButtonTextColor;

            &:enabled:hover {
                background: variables.$plainButtonHoverBgColor;
                color: variables.$plainButtonTextColor;
            }

            &:enabled:active {
                background: variables.$plainButtonActiveBgColor;
                color: variables.$plainButtonTextColor;
            }
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: variables.$buttonBg;
        border-color: transparent;

        &:enabled:hover {
            background: rgba(variables.$buttonBg, variables.$textButtonHoverBgOpacity);
            color: variables.$buttonBg;
            border-color: transparent;
        }

        &:enabled:active {
            background: rgba(variables.$buttonBg, variables.$textButtonActiveBgOpacity);
            color: variables.$buttonBg;
            border-color: transparent;
        }

        &.p-button-plain {
            color: variables.$plainButtonTextColor;

            &:enabled:hover {
                background: variables.$plainButtonHoverBgColor;
                color: variables.$plainButtonTextColor;
            }

            &:enabled:active {
                background: variables.$plainButtonActiveBgColor;
                color: variables.$plainButtonTextColor;
            }
        }
    }

    &:focus {
        @include mixins.focused();
    }

    .p-button-label {
        transition-duration: variables.$transitionDuration;
    }

    .p-button-icon-left {
        margin-right: variables.$inlineSpacing;
    }

    .p-button-icon-right {
        margin-left: variables.$inlineSpacing;
    }

    .p-button-icon-bottom {
        margin-top: variables.$inlineSpacing;
    }

    .p-button-icon-top {
        margin-bottom: variables.$inlineSpacing;
    }

    .p-badge {
        margin-left: variables.$inlineSpacing;
        min-width: variables.$fontSize;
        height: variables.$fontSize;
        line-height: variables.$fontSize;
        color: variables.$buttonBg;
        background-color: variables.$buttonTextColor;
    }

    &.p-button-raised {
        box-shadow: variables.$raisedButtonShadow;
    }

    &.p-button-rounded {
        border-radius: variables.$roundedButtonBorderRadius;
    }

    &.p-button-icon-only {
        width: variables.$buttonIconOnlyWidth;
        padding: variables.$buttonIconOnlyPadding;

        .p-button-icon-left,
        .p-button-icon-right {
            margin: 0;
        }

        &.p-button-rounded {
            border-radius: 50%;
            height: variables.$buttonIconOnlyWidth;
        }
    }

    &.p-button-sm {
        @include mixins.scaledFontSize(variables.$fontSize, variables.$scaleSM);
        @include mixins.scaledPadding(variables.$buttonPadding, variables.$scaleSM);

        .p-button-icon {
            @include mixins.scaledFontSize(variables.$primeIconFontSize, variables.$scaleSM);
        }
    }

    &.p-button-lg {
        @include mixins.scaledFontSize(variables.$fontSize, variables.$scaleLG);
        @include mixins.scaledPadding(variables.$buttonPadding, variables.$scaleLG);

        .p-button-icon {
            @include mixins.scaledFontSize(variables.$primeIconFontSize, variables.$scaleLG);
        }
    }

    &.p-button-loading-label-only {
        .p-button-label {
            margin-left: variables.$inlineSpacing;
        }

        .p-button-loading-icon {
            margin-right: 0;
        }
    }
}

.p-fluid {
    .p-button {
        width: 100%;
    }

    .p-button-icon-only {
        width: variables.$buttonIconOnlyWidth;
    }

    .p-buttonset {
        display: flex;

        .p-button {
            flex: 1;
        }
    }
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
    color: variables.$secondaryButtonTextColor;
    background: variables.$secondaryButtonBg;
    border: variables.$secondaryButtonBorder;

    &:enabled:hover {
        background: variables.$secondaryButtonHoverBg;
        color: variables.$secondaryButtonTextHoverColor;
        border-color: variables.$secondaryButtonHoverBorderColor;
    }

    &:enabled:focus {
        box-shadow: variables.$secondaryButtonFocusShadow;
    }

    &:enabled:active {
        background: variables.$secondaryButtonActiveBg;
        color: variables.$secondaryButtonTextActiveColor;
        border-color: variables.$secondaryButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: variables.$secondaryButtonBg;
        border: variables.$outlinedButtonBorder;

        &:enabled:hover {
            background: rgba(variables.$secondaryButtonBg, variables.$textButtonHoverBgOpacity);
            color: variables.$secondaryButtonBg;
            border: variables.$outlinedButtonBorder;
        }

        &:enabled:active {
            background: rgba(variables.$secondaryButtonBg, variables.$textButtonActiveBgOpacity);
            color: variables.$secondaryButtonBg;
            border: variables.$outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: variables.$secondaryButtonBg;
        border-color: transparent;

        &:enabled:hover {
            background: rgba(variables.$secondaryButtonBg, variables.$textButtonHoverBgOpacity);
            border-color: transparent;
            color: variables.$secondaryButtonBg;
        }

        &:enabled:active {
            background: rgba(variables.$secondaryButtonBg, variables.$textButtonActiveBgOpacity);
            border-color: transparent;
            color: variables.$secondaryButtonBg;
        }
    }
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
    color: variables.$infoButtonTextColor;
    background: variables.$infoButtonBg;
    border: variables.$infoButtonBorder;

    &:enabled:hover {
        background: variables.$infoButtonHoverBg;
        color: variables.$infoButtonTextHoverColor;
        border-color: variables.$infoButtonHoverBorderColor;
    }

    &:enabled:focus {
        box-shadow: variables.$infoButtonFocusShadow;
    }

    &:enabled:active {
        background: variables.$infoButtonActiveBg;
        color: variables.$infoButtonTextActiveColor;
        border-color: variables.$infoButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: variables.$infoButtonBg;
        border: variables.$outlinedButtonBorder;

        &:enabled:hover {
            background: rgba(variables.$infoButtonBg, variables.$textButtonHoverBgOpacity);
            color: variables.$infoButtonBg;
            border: variables.$outlinedButtonBorder;
        }

        &:enabled:active {
            background: rgba(variables.$infoButtonBg, variables.$textButtonActiveBgOpacity);
            color: variables.$infoButtonBg;
            border: variables.$outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: variables.$infoButtonBg;
        border-color: transparent;

        &:enabled:hover {
            background: rgba(variables.$infoButtonBg, variables.$textButtonHoverBgOpacity);
            border-color: transparent;
            color: variables.$infoButtonBg;
        }

        &:enabled:active {
            background: rgba(variables.$infoButtonBg, variables.$textButtonActiveBgOpacity);
            border-color: transparent;
            color: variables.$infoButtonBg;
        }
    }
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
    color: variables.$successButtonTextColor;
    background: variables.$successButtonBg;
    border: variables.$successButtonBorder;

    &:enabled:hover {
        background: variables.$successButtonHoverBg;
        color: variables.$successButtonTextHoverColor;
        border-color: variables.$successButtonHoverBorderColor;
    }

    &:enabled:focus {
        box-shadow: variables.$successButtonFocusShadow;
    }

    &:enabled:active {
        background: variables.$successButtonActiveBg;
        color: variables.$successButtonTextActiveColor;
        border-color: variables.$successButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: variables.$successButtonBg;
        border: variables.$outlinedButtonBorder;

        &:enabled:hover {
            background: rgba(variables.$successButtonBg, variables.$textButtonHoverBgOpacity);
            color: variables.$successButtonBg;
            border: variables.$outlinedButtonBorder;
        }

        &:enabled:active {
            background: rgba(variables.$successButtonBg, variables.$textButtonActiveBgOpacity);
            color: variables.$successButtonBg;
            border: variables.$outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: variables.$successButtonBg;
        border-color: transparent;

        &:enabled:hover {
            background: rgba(variables.$successButtonBg, variables.$textButtonHoverBgOpacity);
            border-color: transparent;
            color: variables.$successButtonBg;
        }

        &:enabled:active {
            background: rgba(variables.$successButtonBg, variables.$textButtonActiveBgOpacity);
            border-color: transparent;
            color: variables.$successButtonBg;
        }
    }
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
    color: variables.$warningButtonTextColor;
    background: variables.$warningButtonBg;
    border: variables.$warningButtonBorder;

    &:enabled:hover {
        background: variables.$warningButtonHoverBg;
        color: variables.$warningButtonTextHoverColor;
        border-color: variables.$warningButtonHoverBorderColor;
    }

    &:enabled:focus {
        box-shadow: variables.$warningButtonFocusShadow;
    }

    &:enabled:active {
        background: variables.$warningButtonActiveBg;
        color: variables.$warningButtonTextActiveColor;
        border-color: variables.$warningButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: variables.$warningButtonBg;
        border: variables.$outlinedButtonBorder;

        &:enabled:hover {
            background: rgba(variables.$warningButtonBg, variables.$textButtonHoverBgOpacity);
            color: variables.$warningButtonBg;
            border: variables.$outlinedButtonBorder;
        }

        &:enabled:active {
            background: rgba(variables.$warningButtonBg, variables.$textButtonActiveBgOpacity);
            color: variables.$warningButtonBg;
            border: variables.$outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: variables.$warningButtonBg;
        border-color: transparent;

        &:enabled:hover {
            background: rgba(variables.$warningButtonBg, variables.$textButtonHoverBgOpacity);
            border-color: transparent;
            color: variables.$warningButtonBg;
        }

        &:enabled:active {
            background: rgba(variables.$warningButtonBg, variables.$textButtonActiveBgOpacity);
            border-color: transparent;
            color: variables.$warningButtonBg;
        }
    }
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
    color: variables.$helpButtonTextColor;
    background: variables.$helpButtonBg;
    border: variables.$helpButtonBorder;

    &:enabled:hover {
        background: variables.$helpButtonHoverBg;
        color: variables.$helpButtonTextHoverColor;
        border-color: variables.$helpButtonHoverBorderColor;
    }

    &:enabled:focus {
        box-shadow: variables.$helpButtonFocusShadow;
    }

    &:enabled:active {
        background: variables.$helpButtonActiveBg;
        color: variables.$helpButtonTextActiveColor;
        border-color: variables.$helpButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: variables.$helpButtonBg;
        border: variables.$outlinedButtonBorder;

        &:enabled:hover {
            background: rgba(variables.$helpButtonBg, variables.$textButtonHoverBgOpacity);
            color: variables.$helpButtonBg;
            border: variables.$outlinedButtonBorder;
        }

        &:enabled:active {
            background: rgba(variables.$helpButtonBg, variables.$textButtonActiveBgOpacity);
            color: variables.$helpButtonBg;
            border: variables.$outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: variables.$helpButtonBg;
        border-color: transparent;

        &:enabled:hover {
            background: rgba(variables.$helpButtonBg, variables.$textButtonHoverBgOpacity);
            border-color: transparent;
            color: variables.$helpButtonBg;
        }

        &:enabled:active {
            background: rgba(variables.$helpButtonBg, variables.$textButtonActiveBgOpacity);
            border-color: transparent;
            color: variables.$helpButtonBg;
        }
    }
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
    color: variables.$dangerButtonTextColor;
    background: variables.$dangerButtonBg;
    border: variables.$dangerButtonBorder;

    &:enabled:hover {
        background: variables.$dangerButtonHoverBg;
        color: variables.$dangerButtonTextHoverColor;
        border-color: variables.$dangerButtonHoverBorderColor;
    }

    &:enabled:focus {
        box-shadow: variables.$dangerButtonFocusShadow;
    }

    &:enabled:active {
        background: variables.$dangerButtonActiveBg;
        color: variables.$dangerButtonTextActiveColor;
        border-color: variables.$dangerButtonActiveBorderColor;
    }

    &.p-button-outlined {
        background-color: transparent;
        color: variables.$dangerButtonBg;
        border: variables.$outlinedButtonBorder;

        &:enabled:hover {
            background: rgba(variables.$dangerButtonBg, variables.$textButtonHoverBgOpacity);
            color: variables.$dangerButtonBg;
            border: variables.$outlinedButtonBorder;
        }

        &:enabled:active {
            background: rgba(variables.$dangerButtonBg, variables.$textButtonActiveBgOpacity);
            color: variables.$dangerButtonBg;
            border: variables.$outlinedButtonBorder;
        }
    }

    &.p-button-text {
        background-color: transparent;
        color: variables.$dangerButtonBg;
        border-color: transparent;

        &:enabled:hover {
            background: rgba(variables.$dangerButtonBg, variables.$textButtonHoverBgOpacity);
            border-color: transparent;
            color: variables.$dangerButtonBg;
        }

        &:enabled:active {
            background: rgba(variables.$dangerButtonBg, variables.$textButtonActiveBgOpacity);
            border-color: transparent;
            color: variables.$dangerButtonBg;
        }
    }
}

.p-button.p-button-link {
    color: variables.$linkButtonColor;
    background: transparent;
    border: transparent;

    &:enabled:hover {
        background: transparent;
        color: variables.$linkButtonHoverColor;
        border-color: transparent;

        .p-button-label {
            text-decoration: variables.$linkButtonTextHoverDecoration;
        }
    }

    &:enabled:focus {
        background: transparent;
        box-shadow: variables.$linkButtonFocusShadow;
        border-color: transparent;
    }

    &:enabled:active {
        background: transparent;
        color: variables.$linkButtonColor;
        border-color: transparent;
    }
}
