@use '../../../variables';
@use '../../mixins';

.p-panelmenu {
    .p-panelmenu-header {
        > a {
            padding: variables.$accordionHeaderPadding;
            border: variables.$accordionHeaderBorder;
            color: variables.$accordionHeaderTextColor;
            background: variables.$accordionHeaderBg;
            font-weight: variables.$accordionHeaderFontWeight;
            border-radius: variables.$borderRadius;
            transition: variables.$listItemTransition;

            .p-panelmenu-icon {
                margin-right: variables.$inlineSpacing;
            }

            .p-menuitem-icon {
                margin-right: variables.$inlineSpacing;
            }

            &:focus {
                @include mixins.focused();
            }
        }

        &:not(.p-highlight):not(.p-disabled) {
            > a:hover {
                background: variables.$accordionHeaderHoverBg;
                border-color: variables.$accordionHeaderHoverBorderColor;
                color: variables.$accordionHeaderTextHoverColor;
            }
        }

        &.p-highlight {
            margin-bottom: 0;

            > a {
                background: variables.$accordionHeaderActiveBg;
                border-color: variables.$accordionHeaderActiveBorderColor;
                color: variables.$accordionHeaderTextActiveColor;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:not(.p-disabled) {
                > a {
                    &:hover {
                        border-color: variables.$accordionHeaderActiveHoverBorderColor;
                        background: variables.$accordionHeaderActiveHoverBg;
                        color: variables.$accordionHeaderTextActiveHoverColor;
                    }
                }
            }
        }
    }

    .p-panelmenu-content {
        padding: variables.$verticalMenuPadding;
        border: variables.$accordionContentBorder;
        background: variables.$accordionContentBg;
        color: variables.$accordionContentTextColor;
        margin-bottom: variables.$accordionSpacing;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: variables.$borderRadius;
        border-bottom-left-radius: variables.$borderRadius;

        .p-menuitem {
            .p-menuitem-link {
                @include mixins.menuitem-link();

                .p-panelmenu-icon {
                    margin-right: variables.$inlineSpacing;
                }
            }
        }

        .p-submenu-list:not(.p-panelmenu-root-submenu) {
            padding: variables.$treeNodeChildrenPadding;
        }
    }

    .p-panelmenu-panel {
        margin-bottom: variables.$accordionSpacing;

        @if variables.$accordionSpacing == 0 {
            .p-panelmenu-header {
                > a {
                    border-radius: 0;
                }
            }

            .p-panelmenu-content {
                border-radius: 0;
            }

            &:not(:first-child) {
                .p-panelmenu-header {
                    > a {
                        border-top: 0 none;
                    }

                    &:not(.p-highlight):not(.p-disabled):hover,
                    &:not(.p-disabled).p-highlight:hover {
                        > a {
                            border-top: 0 none;
                        }
                    }
                }
            }

            &:first-child {
                .p-panelmenu-header {
                    > a {
                        border-top-right-radius: variables.$borderRadius;
                        border-top-left-radius: variables.$borderRadius;
                    }
                }
            }

            &:last-child {
                .p-panelmenu-header:not(.p-highlight) {
                    > a {
                        border-bottom-right-radius: variables.$borderRadius;
                        border-bottom-left-radius: variables.$borderRadius;
                    }
                }

                .p-panelmenu-content {
                    border-bottom-right-radius: variables.$borderRadius;
                    border-bottom-left-radius: variables.$borderRadius;
                }
            }
        }
    }

    .p-menuitem-badge {
        @include mixins.menuitem-badge();
    }
}
