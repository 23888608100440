@use '../variables';

.p-picklist {
    .p-picklist-list {
        .p-picklist-item {
            &:focus {
                background: variables.$emphasis-lower;

                &.p-highlight {
                    background: rgba(variables.$primaryColor, 0.24);
                }
            }
        }
    }
}
