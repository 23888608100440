@use '../../../variables';
@use '../../mixins';

.p-slidemenu {
    padding: variables.$verticalMenuPadding;
    background: variables.$menuBg;
    color: variables.$menuTextColor;
    border: variables.$menuBorder;
    border-radius: variables.$borderRadius;
    width: variables.$menuWidth;

    .p-menuitem-link {
        @include mixins.menuitem-link();
    }

    &.p-slidemenu-overlay {
        background: variables.$overlayMenuBg;
        border: variables.$overlayMenuBorder;
        box-shadow: variables.$overlayMenuShadow;
    }

    .p-slidemenu-list {
        padding: variables.$verticalMenuPadding;
        background: variables.$overlayMenuBg;
        border: variables.$overlayMenuBorder;
        box-shadow: variables.$overlayMenuShadow;
    }

    .p-slidemenu {
        &.p-slidemenu-active {
            > .p-slidemenu-link {
                background: variables.$menuitemActiveBg;

                .p-slidemenu-text {
                    color: variables.$menuitemTextActiveColor;
                }

                .p-slidemenu-icon,
                .p-slidemenu-icon {
                    color: variables.$menuitemIconActiveColor;
                }
            }
        }
    }

    .p-slidemenu-separator {
        border-top: variables.$divider;
        margin: variables.$menuSeparatorMargin;
    }

    .p-slidemenu-icon {
        font-size: variables.$menuitemSubmenuIconFontSize;
    }

    .p-icon {
        width: variables.$menuitemSubmenuIconFontSize;
        height: variables.$menuitemSubmenuIconFontSize;
    }

    .p-slidemenu-backward {
        padding: variables.$menuitemPadding;
        color: variables.$menuitemTextColor;
    }

    .p-menuitem-badge {
        @include mixins.menuitem-badge();
    }
}
