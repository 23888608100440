@use 'sass:color';
@use 'sass:list';
@use '../variables';

@mixin icon-override($icon) {
    &:before {
        content: $icon;
    }
}

@mixin focused() {
    outline: variables.$focusOutline;
    outline-offset: variables.$focusOutlineOffset;
    box-shadow: variables.$focusShadow;
}

@mixin focused-inset() {
    outline: variables.$focusOutline;
    outline-offset: variables.$focusOutlineOffset;
    box-shadow: inset variables.$focusShadow;
}

@mixin focused-input() {
    @include focused();
    border-color: variables.$inputFocusBorderColor;
}

@mixin focused-listitem() {
    outline: variables.$focusOutline;
    outline-offset: variables.$focusOutlineOffset;
    box-shadow: variables.$inputListItemFocusShadow;
}

@mixin invalid-input() {
    border-color: variables.$inputErrorBorderColor;
}

@mixin menuitem-link {
    padding: variables.$menuitemPadding;
    color: variables.$menuitemTextColor;
    border-radius: variables.$menuitemBorderRadius;
    transition: variables.$listItemTransition;
    user-select: none;

    .p-menuitem-text {
        color: variables.$menuitemTextColor;
    }

    .p-menuitem-icon {
        color: variables.$menuitemIconColor;
        margin-right: variables.$inlineSpacing;
    }

    .p-submenu-icon {
        color: variables.$menuitemIconColor;
    }

    &:not(.p-disabled):hover {
        background: variables.$menuitemHoverBg;

        .p-menuitem-text {
            color: variables.$menuitemTextHoverColor;
        }

        .p-menuitem-icon {
            color: variables.$menuitemIconHoverColor;
        }

        .p-submenu-icon {
            color: variables.$menuitemIconHoverColor;
        }
    }

    &:focus {
        @include focused-listitem();
    }
}

@mixin horizontal-rootmenuitem-link {
    padding: variables.$horizontalMenuRootMenuitemPadding;
    color: variables.$horizontalMenuRootMenuitemTextColor;
    border-radius: variables.$horizontalMenuRootMenuitemBorderRadius;
    transition: variables.$listItemTransition;
    user-select: none;

    .p-menuitem-text {
        color: variables.$horizontalMenuRootMenuitemTextColor;
    }

    .p-menuitem-icon {
        color: variables.$horizontalMenuRootMenuitemIconColor;
        margin-right: variables.$inlineSpacing;
    }

    .p-submenu-icon {
        color: variables.$horizontalMenuRootMenuitemIconColor;
        margin-left: variables.$inlineSpacing;
    }

    &:not(.p-disabled):hover {
        background: variables.$horizontalMenuRootMenuitemHoverBg;

        .p-menuitem-text {
            color: variables.$horizontalMenuRootMenuitemTextHoverColor;
        }

        .p-menuitem-icon {
            color: variables.$horizontalMenuRootMenuitemIconHoverColor;
        }

        .p-submenu-icon {
            color: variables.$horizontalMenuRootMenuitemIconHoverColor;
        }
    }

    &:focus {
        @include focused-listitem();
    }
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content;
    }
    :-moz-placeholder {
        @content;
    }
    ::-moz-placeholder {
        @content;
    }
    :-ms-input-placeholder {
        @content;
    }
}

@mixin scaledPadding($val, $scale) {
    padding: list.nth($val, 1) * $scale list.nth($val, 2) * $scale;
}

@mixin scaledFontSize($val, $scale) {
    font-size: $val * $scale;
}

@mixin nested-submenu-indents($val, $index, $length) {
    ul {
        li {
            a {
                padding-left: $val * ($index + 1);
            }

            @if $index < $length {
                @include nested-submenu-indents($val, $index + 2, $length);
            }
        }
    }
}

@mixin action-icon($enabled: true) {
    width: variables.$actionIconWidth;
    height: variables.$actionIconHeight;
    color: variables.$actionIconColor;
    border: variables.$actionIconBorder;
    background: variables.$actionIconBg;
    border-radius: variables.$actionIconBorderRadius;
    transition: variables.$actionIconTransition;

    &:enabled:hover {
        color: variables.$actionIconHoverColor;
        border-color: variables.$actionIconHoverBorderColor;
        background: variables.$actionIconHoverBg;
    }

    &:focus {
        @include focused();
    }
}

@function tint($color, $percentage) {
    @return color.mix(#fff, $color, $percentage);
}

@function shade($color, $percentage) {
    @return color.mix(#000, $color, $percentage);
}

@mixin button-states {
    // <button> and <a> tags support :enabled selector.

    &:enabled,
    &:not(button):not(a):not(.p-disabled) {
        @content;
    }
}

@mixin menuitem-badge {
    background: variables.$badgeBg;
    color: variables.$badgeTextColor;
    font-size: variables.$badgeFontSize;
    font-weight: variables.$badgeFontWeight;
    min-width: variables.$badgeMinWidth;
    height: variables.$badgeHeight;
    line-height: variables.$badgeHeight;
    border-radius: variables.$borderRadius;
    margin-left: variables.$inlineSpacing;
    padding-left: variables.$inlineSpacing;
    padding-right: variables.$inlineSpacing;
}
