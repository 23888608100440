@use 'variables' as *;
@use 'sc-fonts' as *;

p-checkbox {
    &.ng-touched.ng-invalid > .p-checkbox.p-checkbox-checked > .p-checkbox-box {
        background: $errorColor;
        border-color: $errorColor;

        &.p-highlight,
        &.p-focus {
            background: $errorColor;
            border-color: $errorColor;
        }
    }

    .p-checkbox-label {
        @extend .body2Font;

        &.p-disabled {
            opacity: 1;
        }
    }
}
