@use 'sc-fonts' as *;

.field {
    position: relative;
}

.field-checkbox > label {
    @extend .body2Font;
    margin-bottom: 0;
}
