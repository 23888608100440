@use '../variables';
@use 'mixins';

.p-cascadeselect {
    .p-inputtext,
    .p-cascadeselect-trigger {
        background-image: none;
        background: transparent;
    }

    .p-inputtext {
        border: 0 none;
    }

    &:not(.p-disabled).p-focus {
        box-shadow:
            inset 0 0 0 1px variables.$inputFocusBorderColor,
            inset 0 0 0 1px variables.$inputFocusBorderColor,
            inset 0 0 0 1px variables.$inputFocusBorderColor,
            inset 0 0 0 1px variables.$inputFocusBorderColor;
    }
}

.p-cascadeselect-item {
    .p-ink {
        background-color: rgba(variables.$primaryColor, 0.16);
    }
}

.p-input-filled {
    .p-cascadeselect {
        @include mixins.filled-input-wrapper();

        .p-inputtext {
            border: 0 none;
        }

        .p-cascadeselect-label {
            &:hover {
                background-image: none;
                background: transparent;
            }
        }
    }

    p-cascadeselect.ng-touched.ng-invalid {
        .p-cascadeselect {
            @include mixins.invalid-filled-input-wrapper();
        }
    }
}

.p-input-filled {
    .p-float-label {
        .p-cascadeselect {
            .p-cascadeselect-label {
                @include mixins.filled-input-float-label();
            }
        }

        .p-inputwrapper-filled {
            .p-cascadeselect {
                .p-cascadeselect-label {
                    @include mixins.filled-input-float-label();
                }
            }
        }
    }

    .p-cascadeselect.p-invalid {
        @include mixins.invalid-filled-input-wrapper();
    }
}

p-cascadeselect.ng-touched.ng-invalid {
    .p-cascadeselect {
        &:not(.p-disabled).p-focus {
            box-shadow:
                inset 0 0 0 1px variables.$inputErrorBorderColor,
                inset 0 0 0 1px variables.$inputErrorBorderColor,
                inset 0 0 0 1px variables.$inputErrorBorderColor,
                inset 0 0 0 1px variables.$inputErrorBorderColor;
        }
    }
}
