@use '../variables';
@use 'mixins';

.p-inputtext {
    &:enabled:focus {
        box-shadow:
            inset 0 0 0 1px variables.$inputFocusBorderColor,
            inset 0 0 0 1px variables.$inputFocusBorderColor,
            inset 0 0 0 1px variables.$inputFocusBorderColor,
            inset 0 0 0 1px variables.$inputFocusBorderColor;

        &.ng-invalid.ng-touched {
            box-shadow:
                inset 0 0 0 1px variables.$inputErrorBorderColor,
                inset 0 0 0 1px variables.$inputErrorBorderColor,
                inset 0 0 0 1px variables.$inputErrorBorderColor,
                inset 0 0 0 1px variables.$inputErrorBorderColor;
        }
    }
}

.p-input-filled {
    .p-inputtext {
        @include mixins.filled-input();
    }
}
