@use '../../../variables';
@use '../../mixins';

@use 'sass:math';

.p-inputswitch {
    width: variables.$inputSwitchWidth;
    height: variables.$inputSwitchHeight;

    .p-inputswitch-slider {
        background: variables.$inputSwitchSliderOffBg;
        transition: variables.$formElementTransition;
        border-radius: variables.$inputSwitchBorderRadius;

        &:before {
            background: variables.$inputSwitchHandleOffBg;
            width: variables.$inputSwitchHandleWidth;
            height: variables.$inputSwitchHandleHeight;
            left: variables.$inputSwitchSliderPadding;
            margin-top: math.div(-1 * variables.$inputSwitchHandleHeight, 2);
            border-radius: variables.$inputSwitchHandleBorderRadius;
            transition-duration: variables.$transitionDuration;
        }
    }

    &.p-inputswitch-checked {
        .p-inputswitch-slider:before {
            transform: translateX(variables.$inputSwitchHandleWidth);
        }
    }

    &.p-focus {
        .p-inputswitch-slider {
            @include mixins.focused();
        }
    }

    &:not(.p-disabled):hover {
        .p-inputswitch-slider {
            background: variables.$inputSwitchSliderOffHoverBg;
        }
    }

    &.p-inputswitch-checked {
        .p-inputswitch-slider {
            background: variables.$inputSwitchSliderOnBg;

            &:before {
                background: variables.$inputSwitchHandleOnBg;
            }
        }

        &:not(.p-disabled):hover {
            .p-inputswitch-slider {
                background: variables.$inputSwitchSliderOnHoverBg;
            }
        }
    }
}

p-inputswitch.ng-touched.ng-invalid > .p-inputswitch {
    @include mixins.invalid-input();
}
