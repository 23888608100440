@use 'variables' as *;
@use 'sc-colors' as color;

$transition-duration: 0.3s;

.p-dropdown {
    height: $inputHeight;

    .p-dropdown-trigger-icon {
        transition-duration: $transition-duration;
    }

    &.p-dropdown-open {
        .p-dropdown-trigger-icon {
            transform: rotate(180deg);
            transition-duration: $transition-duration;
        }
    }

    &.p-disabled {
        background-color: color.$grey4Color;
        border-color: color.$grey4Color;
        opacity: unset;

        >.p-dropdown-trigger {
            display: none;
        }
    }

    &:not(.p-disabled).p-focus {
        box-shadow: none;
    }
}

.p-dropdown-panel {
    .p-dropdown-items {
        .p-dropdown-item {

            &.p-highlight,
            &:not(.p-highlight):not(.p-disabled):hover {
                font-weight: 600;
            }
        }
    }
}

.p-inputgroup .p-inputwrapper>.p-dropdown {
    width: 100%;
}