@use '../variables';

.p-button {
    font-weight: 500;
    min-width: 4rem;

    &.p-button-icon-only {
        min-width: auto;
    }

    &:enabled:focus {
        background: rgba(variables.$buttonBg, 0.76);
    }

    &:enabled:active {
        background: rgba(variables.$buttonBg, 0.68);
    }

    .p-ink {
        background-color: rgba(255, 255, 255, 0.32);
    }

    &.p-button-text,
    &.p-button-outlined {
        &:enabled:focus {
            background: rgba(variables.$buttonBg, 0.12);
        }

        &:enabled:active {
            background: rgba(variables.$buttonBg, 0.16);
        }

        .p-ink {
            background-color: rgba(variables.$buttonBg, 0.16);
        }
    }

    &.p-button-outlined {
        box-shadow: inset 0 0 0 1px;

        &:enabled:focus {
            box-shadow: inset 0 0 0 1px;
        }

        &:enabled:active {
            box-shadow: inset 0 0 0 1px;
        }
    }

    &:disabled {
        background-color: variables.$emphasis-lower !important;
        color: variables.$emphasis-low !important;
        opacity: 1;

        &.p-button-text {
            background-color: transparent !important;
            color: variables.$emphasis-low !important;
        }

        &.p-button-outlined {
            background-color: transparent !important;
            color: variables.$emphasis-low !important;
            border-color: variables.$emphasis-low !important;
        }
    }

    &.p-button-raised:enabled:focus {
        box-shadow: variables.$raisedButtonShadow;
    }
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
    &:enabled:focus {
        background: rgba(variables.$secondaryButtonBg, 0.76);
    }

    &:enabled:active {
        background: rgba(variables.$secondaryButtonBg, 0.68);
    }

    &.p-button-text,
    &.p-button-outlined {
        &:enabled:focus {
            background: rgba(variables.$secondaryButtonBg, 0.12);
        }

        &:enabled:active {
            background: rgba(variables.$secondaryButtonBg, 0.16);
        }

        .p-ink {
            background-color: rgba(variables.$secondaryButtonBg, 0.16);
        }
    }
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
    &:enabled:focus {
        background: rgba(variables.$infoButtonBg, 0.76);
    }

    &:enabled:active {
        background: rgba(variables.$infoButtonBg, 0.68);
    }

    &.p-button-text,
    &.p-button-outlined {
        &:enabled:focus {
            background: rgba(variables.$infoButtonBg, 0.12);
        }

        &:enabled:active {
            background: rgba(variables.$infoButtonBg, 0.16);
        }

        .p-ink {
            background-color: rgba(variables.$infoButtonBg, 0.16);
        }
    }
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
    &:enabled:focus {
        background: rgba(variables.$successButtonBg, 0.76);
    }

    &:enabled:active {
        background: rgba(variables.$successButtonBg, 0.68);
    }

    &.p-button-text,
    &.p-button-outlined {
        &:enabled:focus {
            background: rgba(variables.$successButtonBg, 0.12);
        }

        &:enabled:active {
            background: rgba(variables.$successButtonBg, 0.16);
        }

        .p-ink {
            background-color: rgba(variables.$successButtonBg, 0.16);
        }
    }
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
    &:enabled:focus {
        background: rgba(variables.$warningButtonBg, 0.76);
    }

    &:enabled:active {
        background: rgba(variables.$warningButtonBg, 0.68);
    }

    &.p-button-text,
    &.p-button-outlined {
        &:enabled:focus {
            background: rgba(variables.$warningButtonBg, 0.12);
        }

        &:enabled:active {
            background: rgba(variables.$warningButtonBg, 0.16);
        }

        .p-ink {
            background-color: rgba(variables.$warningButtonBg, 0.16);
        }
    }
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
    &:enabled:focus {
        background: rgba(variables.$helpButtonBg, 0.76);
    }

    &:enabled:active {
        background: rgba(variables.$helpButtonBg, 0.68);
    }

    &.p-button-text,
    &.p-button-outlined {
        &:enabled:focus {
            background: rgba(variables.$helpButtonBg, 0.12);
        }

        &:enabled:active {
            background: rgba(variables.$helpButtonBg, 0.16);
        }

        .p-ink {
            background-color: rgba(variables.$helpButtonBg, 0.16);
        }
    }
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
    &:enabled:focus {
        background: rgba(variables.$dangerButtonBg, 0.76);
    }

    &:enabled:active {
        background: rgba(variables.$dangerButtonBg, 0.68);
    }

    &.p-button-text,
    &.p-button-outlined {
        &:enabled:focus {
            background: rgba(variables.$dangerButtonBg, 0.12);
        }

        &:enabled:active {
            background: rgba(variables.$dangerButtonBg, 0.16);
        }

        .p-ink {
            background-color: rgba(variables.$dangerButtonBg, 0.16);
        }
    }
}
