@use 'variables' as *;
@use 'sc-colors' as color;

@use 'primeflex/primeflex.scss';
@use 'projects/common/styles/theme/sass/fonts';
@use 'projects/common/styles/theme/sass/theme/theme';

@forward 'primeflex/core/variables';

@use 'primeng/p-accordion';
@use 'primeng/p-button';
@use 'primeng/p-calendar';
@use 'primeng/p-checkbox';
@use 'primeng/p-datatable';
@use 'primeng/p-inputtext';
@use 'primeng/p-message';
@use 'primeng/p-tieredmenu';
@use 'primeng/p-input';
@use 'primeng/p-dropdown';
@use 'primeng/p-dialog';
@use 'primeng/p-field';
@use 'primeng/p-autocomplete';
@use 'primeng/p-progressbar';
@use 'primeng/p-splitbutton';
@use 'primeng/p-tabmenu';
@use 'primeng/p-toast';
@use 'primeng/p-multiselect';
@use 'primeng/p-tooltip';

@use 'tinymce';

@use 'sc-fonts';
@use 'sc-icons';
@use 'sc-statuses';
@use 'sc-text';
@use 'sc-validation';
@use 'sc-progressspinner';
@use 'sc-table-dropdown';

:root {
    font-family: var(--font-family);
    font-size: $scFontSize;
    height: 100%;

    body {
        padding: 0;
        margin: 0;
        min-height: 100vh;
        color: $textColor;
        height: 100%;
    }
}

router-outlet + *,
.sc-flex-full-height {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0px;
    height: 100%;
}

main {
    padding-top: $mainPaddingTop;
    padding-bottom: $mainPaddingBottom;

    > ng-component > * {
        width: $mainContentWidth;
        margin: 0 auto;
    }
}

a {
    color: $textColor;
    cursor: pointer;

    &.sc-back-link {
        text-decoration-line: none;
        @extend .body2Font;

        &:hover {
            color: color.$redColor;
        }

        i {
            color: color.$redColor;
        }
    }
}

.sc-menu-content {
    border-left: $scCardBorder;
}

.sc-card {
    border: $scCardBorder;
    background: color.$whiteColor;

    .sc-card-header {
        border-bottom: $scCardBorder;
    }
}

.sc-grey-card {
    background-color: color.$grey4Color;
}

.sc-red {
    color: color.$redColor !important;
}

.sc-grey-1 {
    color: color.$grey1Color !important;
}

.sc-margin-auto {
    margin: auto;
}

.sc-toolbar {
    width: $mainContentWidth;
    margin: 0 auto;
    height: 70px;
}