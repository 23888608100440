@use 'variables' as *;

.sc-overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 100%;
}

@for $i from 1 through 12 {
    .col-#{$i} {
        overflow-wrap: anywhere;
    }
}
