@use 'sass:color';
@use 'sass:list';
@use '../../../variables';
@use '../../mixins';

.p-tree {
    border: variables.$panelContentBorder;
    background: variables.$panelContentBg;
    color: variables.$panelContentTextColor;
    padding: variables.$panelContentPadding;
    border-radius: variables.$borderRadius;

    .p-tree-container {
        .p-treenode {
            padding: variables.$treeNodePadding;

            .p-treenode-content {
                border-radius: variables.$borderRadius;
                transition: variables.$listItemTransition;
                padding: variables.$treeNodeContentPadding;

                .p-tree-toggler {
                    margin-right: variables.$inlineSpacing;
                    @include mixins.action-icon();
                }

                .p-treenode-icon {
                    margin-right: variables.$inlineSpacing;
                    color: variables.$treeNodeIconColor;
                }

                .p-checkbox {
                    margin-right: variables.$inlineSpacing;

                    .p-indeterminate {
                        .p-checkbox-icon {
                            color: variables.$textColor;
                        }
                    }
                }

                &:focus {
                    @include mixins.focused-listitem();
                }

                &.p-highlight {
                    background: variables.$highlightBg;
                    color: variables.$highlightTextColor;

                    .p-tree-toggler,
                    .p-treenode-icon {
                        color: variables.$highlightTextColor;

                        &:hover {
                            color: variables.$highlightTextColor;
                        }
                    }
                }

                &.p-treenode-selectable:not(.p-highlight):hover {
                    background: variables.$inputListItemHoverBg;
                    color: variables.$inputListItemTextHoverColor;
                }

                &.p-treenode-dragover {
                    background: variables.$inputListItemHoverBg;
                    color: variables.$inputListItemTextHoverColor;
                }
            }
        }
    }

    .p-tree-filter-container {
        margin-bottom: variables.$inlineSpacing;

        .p-tree-filter {
            width: 100%;
            padding-right: list.nth(variables.$inputPadding, 2) + variables.$primeIconFontSize;
        }

        .p-tree-filter-icon {
            right: list.nth(variables.$inputPadding, 2);
            color: variables.$inputIconColor;
        }
    }

    .p-treenode-children {
        padding: variables.$treeNodeChildrenPadding;
    }

    .p-tree-loading-icon {
        font-size: variables.$loadingIconFontSize;

        &.p-icon {
            width: variables.$loadingIconFontSize;
            height: variables.$loadingIconFontSize;
        }
    }

    .p-treenode-droppoint {
        &.p-treenode-droppoint-active {
            background-color: color.scale(variables.$highlightBg, $lightness: -20%);
        }
    }

    &.p-tree-horizontal {
        .p-treenode {
            .p-treenode-content {
                border-radius: variables.$borderRadius;
                border: variables.$panelContentBorder;
                background-color: variables.$panelContentBg;
                color: variables.$panelContentTextColor;
                padding: variables.$treeNodeContentPadding;
                transition: variables.$listItemTransition;

                &.p-highlight {
                    background-color: variables.$highlightBg;
                    color: variables.$highlightTextColor;

                    .p-treenode-icon {
                        color: variables.$highlightTextColor;
                    }
                }

                .p-tree-toggler {
                    margin-right: variables.$inlineSpacing;
                }

                .p-treenode-icon {
                    color: variables.$treeNodeIconColor;
                    margin-right: variables.$inlineSpacing;
                }

                .p-checkbox {
                    margin-right: variables.$inlineSpacing;
                }

                .p-treenode-label:not(.p-highlight):hover {
                    background-color: inherit;
                    color: inherit;
                }

                &.p-treenode-selectable:not(.p-highlight):hover {
                    background: variables.$inputListItemHoverBg;
                    color: variables.$inputListItemTextHoverColor;
                }

                &:focus {
                    @include mixins.focused();
                }
            }
        }
    }
}
