@use 'variables' as *;
@use 'sc-fonts' as *;
@use 'sc-colors' as colors;

.sc-validation-errors {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    left: 1px;
    bottom: -1.5rem;
    position: absolute;
    padding-left: inherit;

    &:hover {
        overflow: visible;
        text-overflow: unset;

        small {
            white-space: normal !important;
            position: relative;
        }
    }

    small {
        white-space: nowrap !important;
        z-index: $mainZindex + 100;

        @extend .caption2Font;
    }

    .p-inputgroup > & {
        left: unset;
        bottom: unset;
        margin-top: 40px;
        font-size: $scFontSize;
        font-weight: normal;
    }
}

.sc-validation-error-panel.p-overlaypanel {
    $validationBgColor: #f8e5e5;

    color: colors.$redColor;
    background-color: $validationBgColor;
    box-shadow: none;

    @extend .body1Font;

    &:before,
    &:after {
        border-bottom-color: $validationBgColor;
    }

    .p-overlaypanel-content {
        padding: 1rem;
    }
}

.grid > [class*='col'] .sc-validation-errors {
    padding-left: $gutter;
}

p-checkbox + .sc-validation-errors,
p-radiobutton + .sc-validation-errors {
    bottom: -1rem;
}

p-calendar + .sc-validation-errors {
    bottom: 0;
    padding-left: 0 !important;
}
