@use 'primeflex/core/variables' as *;

$_tokenPadding: $spacer * 0.25;

p-autocomplete.ng-touched.ng-invalid {
    .p-autocomplete > .p-inputtext {
        &:enabled:focus {
            box-shadow: none;
        }
    }
}

.p-autocomplete {
    .p-float-label & {
        width: 100%;

        .p-inputtext {
            flex: 1;
        }
    }

    &.p-autocomplete-multiple .p-autocomplete-multiple-container {
        // TODO Remove once PrimeNg autocomplete supports multiline
        flex-wrap: wrap;
        gap: 0.33rem;

        &.p-focus {
            box-shadow: none;
        }

        .p-autocomplete-token {
            padding-top: $_tokenPadding;
            padding-bottom: $_tokenPadding;

            $tokenPadding: 0.2rem;
            padding-top: $tokenPadding;
            padding-bottom: $tokenPadding;

            a {
                max-width: 10rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}
