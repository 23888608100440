@use '../../../variables';
@use '../../mixins';

.p-carousel {
    .p-carousel-content {
        .p-carousel-prev,
        .p-carousel-next {
            @include mixins.action-icon();
            margin: variables.$inlineSpacing;
        }
    }

    .p-carousel-indicators {
        padding: variables.$carouselIndicatorsPadding;

        .p-carousel-indicator {
            margin-right: variables.$inlineSpacing;
            margin-bottom: variables.$inlineSpacing;

            button {
                background-color: variables.$carouselIndicatorBg;
                width: variables.$carouselIndicatorWidth;
                height: variables.$carouselIndicatorHeight;
                transition: variables.$actionIconTransition;
                border-radius: variables.$carouselIndicatorBorderRadius;

                &:hover {
                    background: variables.$carouselIndicatorHoverBg;
                }
            }

            &.p-highlight {
                button {
                    background: variables.$highlightBg;
                    color: variables.$highlightTextColor;
                }
            }
        }
    }
}
