.default {
    color: #ec9c0f;
}

.success {
    color: #129870;
}

.danger {
    color: #bb171a;
}

.warning {
    color: #ec9c0f;
}
