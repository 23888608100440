@use 'sass:list';
@use '../../../variables';
@use '../../mixins';

p-inputnumber.ng-touched.ng-invalid > .p-inputnumber > .p-inputtext {
    @include mixins.invalid-input();
}

p-inputnumber.p-inputnumber-clearable {
    .p-inputnumber-input {
        padding-right: list.nth(variables.$inputPadding, 2) * 2 + variables.$primeIconFontSize;
    }

    .p-inputnumber-clear-icon {
        color: variables.$inputIconColor;
        right: list.nth(variables.$inputPadding, 2);
    }
}

p-inputnumber.p-inputnumber-clearable {
    .p-inputnumber-buttons-stacked {
        .p-inputnumber-clear-icon {
            right: variables.$buttonIconOnlyWidth + list.nth(variables.$inputPadding, 2);
        }
    }

    .p-inputnumber-buttons-horizontal {
        .p-inputnumber-clear-icon {
            right: variables.$buttonIconOnlyWidth + list.nth(variables.$inputPadding, 2);
        }
    }
}
