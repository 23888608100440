@use 'sass:list';
@use '../../../variables';
@use '../../mixins';

.p-inputtext {
    font-family: variables.$fontFamily;
    font-size: variables.$inputTextFontSize;
    color: variables.$inputTextColor;
    background: variables.$inputBg;
    padding: variables.$inputPadding;
    border: variables.$inputBorder;
    transition: variables.$formElementTransition;
    appearance: none;
    border-radius: variables.$borderRadius;

    &:enabled:hover {
        border-color: variables.$inputHoverBorderColor;
    }

    &:enabled:focus {
        @include mixins.focused-input();
    }

    &.ng-touched.ng-invalid {
        @include mixins.invalid-input();
    }

    &.p-inputtext-sm {
        @include mixins.scaledFontSize(variables.$inputTextFontSize, variables.$scaleSM);
        @include mixins.scaledPadding(variables.$inputPadding, variables.$scaleSM);
    }

    &.p-inputtext-lg {
        @include mixins.scaledFontSize(variables.$inputTextFontSize, variables.$scaleLG);
        @include mixins.scaledPadding(variables.$inputPadding, variables.$scaleLG);
    }
}

.p-float-label > label {
    left: list.nth(variables.$inputPadding, 2);
    color: variables.$inputPlaceholderTextColor;
    transition-duration: variables.$transitionDuration;
}

.p-float-label > .ng-invalid.ng-touched + label {
    color: variables.$inputErrorBorderColor;
}

.p-input-icon-left > .p-icon-wrapper.p-icon,
.p-input-icon-left > i:first-of-type {
    left: list.nth(variables.$inputPadding, 2);
    color: variables.$inputIconColor;
}

.p-input-icon-left > .p-inputtext {
    padding-left: list.nth(variables.$inputPadding, 2) * 2 + variables.$primeIconFontSize;
}

.p-input-icon-left.p-float-label > label {
    left: list.nth(variables.$inputPadding, 2) * 2 + variables.$primeIconFontSize;
}

.p-input-icon-right > .p-icon-wrapper,
.p-input-icon-right > i:last-of-type {
    right: list.nth(variables.$inputPadding, 2);
    color: variables.$inputIconColor;
}

.p-input-icon-right > .p-inputtext {
    padding-right: list.nth(variables.$inputPadding, 2) * 2 + variables.$primeIconFontSize;
}

@include mixins.placeholder {
    color: variables.$inputPlaceholderTextColor;
}

.p-input-filled {
    .p-inputtext {
        background-color: variables.$inputFilledBg;

        &:enabled:hover {
            background-color: variables.$inputFilledHoverBg;
        }

        &:enabled:focus {
            background-color: variables.$inputFilledFocusBg;
        }
    }
}

.p-inputtext-sm {
    .p-inputtext {
        @include mixins.scaledFontSize(variables.$inputTextFontSize, variables.$scaleSM);
        @include mixins.scaledPadding(variables.$inputPadding, variables.$scaleSM);
    }
}

.p-inputtext-lg {
    .p-inputtext {
        @include mixins.scaledFontSize(variables.$inputTextFontSize, variables.$scaleLG);
        @include mixins.scaledPadding(variables.$inputPadding, variables.$scaleLG);
    }
}
