// Primary colors
$primaryColor: #7f1418 !default;
$hoverColor: #a90003 !default;

$redColor: #7f1418 !default;
$blackColor: #283a42 !default;
$whiteColor: #ffffff !default;
$lightRed: #fcf2f3 !default;

// Shades of greys
$grey1Color: #939ca0 !default;
$grey2Color: #cbcbcb !default;
$grey3Color: #d8dfec !default;
$grey4Color: #f5f6f7 !default;
$grey5Color: #f7fafd !default;
$grey6Color: #fcfdfe !default;
$grey7Color: #abacae !default;

// Complementary
$greenColor: #0e941e !default;
$greenLightColor: #eefaf0 !default;
$greenDarkColor: #247949 !default;
$blueColor: #3367cc !default;
$yellowColor: #ec9c0f !default;
$blueLightColor: #eef3ff !default;
$redLightColor: #fff6f7 !default;