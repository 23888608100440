@use 'sass:list';
@use '../../../variables';
@use '../../mixins';

@use 'sass:math';

p-inputmask.ng-touched.ng-invalid > .p-inputtext {
    @include mixins.invalid-input();
}

p-inputmask.p-inputmask-clearable {
    .p-inputtext {
        padding-right: list.nth(variables.$inputPadding, 2) * 2 + variables.$primeIconFontSize;
    }

    .p-inputmask-clear-icon {
        color: variables.$inputIconColor;
        right: list.nth(variables.$inputPadding, 2);
    }
}
