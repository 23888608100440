// https://fonts.google.com/specimen/Roboto
$blackWeight: 900;
$mediumWeight: 500;
$fontFamily: Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !default;

.title1Font {
    font-size: 2.71rem;
    font-weight: $blackWeight;
    line-height: 140%;
}

.title2Font {
    font-size: 2.29rem;
    font-weight: bold;
    line-height: 140%;
}

.title3Font {
    font-size: 1.43rem;
    font-weight: bold;
    line-height: 140%;
}

.title4Font {
    font-size: 1.14rem;
    font-weight: bold;
}

.subtitle1Font {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.subtitle2Font {
    font-size: 1rem;
    text-transform: uppercase;
}

.subtitle3Font {
    font-size: 1rem;
    font-weight: bold;
}

.subtitle4Font {
    font-size: 1rem;
    font-weight: bold;
}

.body1Font {
    font-size: 1rem;
    font-weight: $mediumWeight;
    line-height: 140%;
}

.body2Font {
    font-size: 1rem;
    line-height: 160%;
}

.caption1Font,
.caption2Font,
.caption3Font,
.caption4Font {
    font-size: 0.86rem;
}

.caption1Font {
    font-style: italic;
    line-height: 150%;
}

.caption2Font {
    font-weight: normal;
    line-height: 150%;
}

.caption3Font {
    font-weight: $blackWeight;
    line-height: 140%;
    text-transform: uppercase;
}

.caption4Font {
    font-weight: 400;
    line-height: 140%;
}

.errorCodeFont {
    font-size: 4.57rem;
    font-weight: 700;
    line-height: 5.71rem;
}
