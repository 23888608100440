@use '../../../variables';

.p-divider {
    .p-divider-content {
        background-color: variables.$panelContentBg;
    }

    &.p-divider-horizontal {
        margin: variables.$dividerHorizontalMargin;
        padding: variables.$dividerHorizontalPadding;

        &:before {
            border-top: variables.$dividerSize variables.$dividerColor;
        }

        .p-divider-content {
            padding: 0 variables.$inlineSpacing;
        }
    }

    &.p-divider-vertical {
        margin: variables.$dividerVerticalMargin;
        padding: variables.$dividerVerticalPadding;

        &:before {
            border-left: variables.$dividerSize variables.$dividerColor;
        }

        .p-divider-content {
            padding: variables.$inlineSpacing 0;
        }
    }
}
