@use '../variables';
@use 'mixins';

.p-calendar-w-btn {
    border: variables.$inputBorder;
    background: variables.$inputBg;
    border-radius: variables.$borderRadius;
    transition: variables.$formElementTransition;

    .p-inputtext {
        background-image: none;
        background-color: transparent;
        border: 0 none;

        &:enabled:focus {
            box-shadow: none;
        }
    }

    .p-datepicker-trigger.p-button {
        background-color: transparent;
        border: 0 none;

        span {
            color: variables.$inputIconColor;
        }

        &:enabled:hover {
            background: rgba(variables.$overlayColor, 0.04);
        }

        &:enabled:active,
        &:focus {
            background: rgba(variables.$overlayColor, 0.12);
        }

        &:disabled {
            background-color: transparent !important;
        }
    }

    &:not(.p-calendar-disabled):hover {
        border-color: variables.$inputHoverBorderColor;
    }

    &:not(.p-calendar-disabled).p-focus {
        border-color: variables.$inputFocusBorderColor;
        box-shadow:
            inset 0 0 0 1px variables.$inputFocusBorderColor,
            inset 0 0 0 1px variables.$inputFocusBorderColor,
            inset 0 0 0 1px variables.$inputFocusBorderColor,
            inset 0 0 0 1px variables.$inputFocusBorderColor;
    }

    &.p-calendar-disabled {
        opacity: variables.$disabledOpacity;
    }
}

.p-datepicker {
    .p-datepicker-header {
        border-bottom: 0 none;

        .p-datepicker-title {
            margin: 0 auto 0 0;
            order: 1;
        }

        .p-datepicker-prev {
            order: 2;
        }

        .p-datepicker-next {
            order: 3;
        }
    }

    table {
        th {
            border-bottom: variables.$calendarHeaderBorder;
            color: variables.$emphasis-low();
            font-weight: 400;
            font-size: 0.875rem;
        }

        td {
            &.p-datepicker-today {
                > span {
                    box-shadow: 0 0 0 1px rgba(variables.$overlayColor, 0.38);
                }

                &.p-highlight {
                    box-shadow: 0 0 0 1px variables.$highlightBg;
                }
            }
        }
    }
}

p-calendar.ng-touched.ng-invalid {
    .p-inputtext {
        &:enabled:focus {
            box-shadow:
                inset 0 0 0 1px variables.$inputErrorBorderColor,
                inset 0 0 0 1px variables.$inputErrorBorderColor,
                inset 0 0 0 1px variables.$inputErrorBorderColor,
                inset 0 0 0 1px variables.$inputErrorBorderColor;
        }
    }

    .p-calendar-w-btn {
        border-color: variables.$inputErrorBorderColor;

        .p-inputtext {
            &:enabled:focus {
                box-shadow: none;
            }
        }

        &:not(.p-disabled).p-focus {
            box-shadow:
                inset 0 0 0 1px variables.$inputErrorBorderColor,
                inset 0 0 0 1px variables.$inputErrorBorderColor,
                inset 0 0 0 1px variables.$inputErrorBorderColor,
                inset 0 0 0 1px variables.$inputErrorBorderColor;
        }
    }
}

.p-input-filled {
    .p-calendar-w-btn {
        @include mixins.filled-input-wrapper-default();

        &:not(.p-calendar-disabled) {
            @include mixins.filled-input-wrapper-interaction();
        }

        .p-inputtext {
            border: 0 none;
        }
    }

    p-calendar.ng-touched.ng-invalid {
        .p-inputtext {
            @include mixins.invalid-filled-input();
        }

        .p-calendar-w-btn {
            @include mixins.invalid-filled-input-wrapper();

            .p-inputtext {
                border: 0 none;
                background-image: none;
            }
        }
    }
}
