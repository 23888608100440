@use '../variables';

.p-panelmenu {
    .p-panelmenu-panel {
        box-shadow:
            0 3px 1px -2px rgba(0, 0, 0, 0.2),
            0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 1px 5px 0 rgba(0, 0, 0, 0.12);
        margin-bottom: 0;
        border-radius: 0;
        position: relative;
        transition: margin-bottom 225ms;

        &:first-child {
            border-top-left-radius: variables.$borderRadius;
            border-top-right-radius: variables.$borderRadius;
        }

        &:last-child {
            border-bottom-left-radius: variables.$borderRadius;
            border-bottom-right-radius: variables.$borderRadius;
        }

        .p-panelmenu-header {
            .p-panelmenu-icon {
                order: 1;
                margin-left: auto;
                margin-right: 0;
            }

            &.p-disabled {
                opacity: 1;

                .p-panelmenu-header-link > * {
                    opacity: variables.$disabledOpacity;
                }
            }

            .p-panelmenu-header-link {
                &:focus {
                    background: variables.$accordionHeaderHoverBg;
                }
            }
        }

        .p-menuitem {
            .p-menuitem-link {
                &:focus {
                    background: variables.$emphasis-lower;
                }
            }
        }
    }
}
