@use '../../../variables';
@use '../../mixins';

.p-tabview {
    .p-tabview-nav {
        background: variables.$tabviewNavBg;
        border: variables.$tabviewNavBorder;
        border-width: variables.$tabviewNavBorderWidth;

        li {
            margin-right: variables.$tabviewHeaderSpacing;

            .p-tabview-nav-link {
                border: variables.$tabviewHeaderBorder;
                border-width: variables.$tabviewHeaderBorderWidth;
                border-color: variables.$tabviewHeaderBorderColor;
                background: variables.$tabviewHeaderBg;
                color: variables.$tabviewHeaderTextColor;
                padding: variables.$tabviewHeaderPadding;
                font-weight: variables.$tabviewHeaderFontWeight;
                border-top-right-radius: variables.$borderRadius;
                border-top-left-radius: variables.$borderRadius;
                transition: variables.$listItemTransition;
                margin: variables.$tabviewHeaderMargin;

                &:not(.p-disabled):focus {
                    @include mixins.focused-inset();
                }
            }

            &:not(.p-highlight):not(.p-disabled):hover {
                .p-tabview-nav-link {
                    background: variables.$tabviewHeaderHoverBg;
                    border-color: variables.$tabviewHeaderHoverBorderColor;
                    color: variables.$tabviewHeaderTextHoverColor;
                }
            }

            &.p-highlight {
                .p-tabview-nav-link {
                    background: variables.$tabviewHeaderActiveBg;
                    border-color: variables.$tabviewHeaderActiveBorderColor;
                    color: variables.$tabviewHeaderTextActiveColor;
                }
            }
        }
    }

    .p-tabview-left-icon {
        margin-right: variables.$inlineSpacing;
    }

    .p-tabview-right-icon {
        margin-left: variables.$inlineSpacing;
    }

    .p-tabview-close {
        margin-left: variables.$inlineSpacing;
    }

    .p-tabview-nav-btn.p-link {
        background: variables.$tabviewHeaderActiveBg;
        color: variables.$tabviewHeaderTextActiveColor;
        width: variables.$buttonIconOnlyWidth;
        box-shadow: variables.$raisedButtonShadow;
        border-radius: 0;

        &:focus {
            @include mixins.focused-inset();
        }
    }

    .p-tabview-panels {
        background: variables.$tabviewContentBg;
        padding: variables.$tabviewContentPadding;
        border: variables.$tabviewContentBorder;
        color: variables.$tabviewContentTextColor;
        border-bottom-right-radius: variables.$borderRadius;
        border-bottom-left-radius: variables.$borderRadius;
    }
}
