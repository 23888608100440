@use 'variables' as *;
@use 'sc-fonts' as *;
@use 'sc-colors' as colors;

.p-datatable {
    min-height: 0px !important;

    .p-datatable-loading-overlay.p-component-overlay {
        background-color: colors.$grey1Color;
        opacity: 0.32;

        .p-datatable-loading-icon {
            color: colors.$primaryColor;
        }
    }

    .p-datatable-header {
        background: none;
        border: none;

        &:empty {
            border: none;
            padding: 0 !important;
        }

        div.inline-flex {
            >*:not(:last-child) {
                margin-right: $spacer * 0.75;
            }
        }
    }

    .p-datatable-thead {
        th {
            cursor: unset;
            text-overflow: ellipsis;

            &.sc-reordering-column {
                width: 3em;
            }

            p-sorticon {
                margin-right: 0.6em;

                >i {
                    font-size: 0.8em;
                }

                .p-sortable-column-badge {
                    color: $primaryColor;
                    background-color: rgba($primaryColor, 0.02);
                }
            }

            &.sc-selectable-column {
                width: 3em;
            }

            >.p-inputgroup {
                .p-button {
                    height: unset !important;

                    .p-button-icon {
                        color: colors.$whiteColor;
                    }
                }
            }

            .p-multiselect {
                width: 100%;
                max-width: 100%;
            }
        }

        .sc-table-filter-row {
            th {
                padding: 0.5rem;
            }
        }
    }

    .p-datatable-tbody {
        td {
            @extend .body2Font;
            text-overflow: ellipsis;
            overflow-x: hidden;

            p-celleditor {
                text-overflow: ellipsis;
                overflow-x: hidden;
            }

            &.p-cell-editing {
                padding: 0;

                p-celleditor {
                    width: 100%;

                    .fas.fa-pen {
                        color: colors.$grey3Color;
                    }

                    .p-inputtextarea {
                        width: 100%;
                        display: block;
                    }
                }
            }

            &.sc-td-white-space-normal {
                white-space: normal;
            }

            &.sc-reordering-column {
                width: 3em;
                text-overflow: unset;
            }

            &.sc-selectable-column {
                width: 3em;
                text-overflow: unset;
            }
        }
    }

    .sc-actions-column {
        $actionColumnBaseWidth: 4.625rem;

        .p-button {
            color: colors.$blackColor;

            &.p-button-icon-only.p-button-rounded {
                height: 1rem !important;

                &:hover {
                    background: none;
                }
            }
        }

        @for $i from 1 through 3 {
            &.sc-actions-column-#{$i} {
                width: $actionColumnBaseWidth * $i;
            }
        }

        i {
            cursor: pointer;
        }
    }

    .p-paginator {
        background: none;

        .p-paginator-current {
            padding: unset;
            margin-right: auto;
            order: 1;
        }

        .p-paginator-left-content {
            order: 2;
            margin-right: unset;
        }

        .p-paginator-first {
            order: 3;
        }

        .p-paginator-prev {
            order: 4;
        }

        .p-paginator-pages {
            order: 5;

            .p-paginator-element.p-highlight {
                color: colors.$redColor;
                background: none;
                font-weight: bold;
            }
        }

        .p-paginator-next {
            order: 6;
        }

        .p-paginator-last {
            order: 7;
        }

        .p-paginator-right-content {
            order: 8;
        }

        .sc-paginator-rows-per-page {
            order: 9;
        }

        p-dropdown[styleclass='p-paginator-rpp-options'] {
            order: 10;
        }

        .p-paginator-first,
        .p-paginator-prev,
        .p-paginator-next,
        .p-paginator-last {
            border: 1px solid colors.$grey3Color;
            border-radius: 2px;

            span {
                font-weight: bold;
            }

            &:not(.p-disabled) {
                color: colors.$redColor !important;
            }
        }
    }
}