@use 'sass:list';
@use '../../../variables';
@use '../../mixins';

@use 'sass:math';

.p-chips {
    .p-chips-multiple-container {
        padding: math.div(list.nth(variables.$inputPadding, 1), 2) list.nth(variables.$inputPadding, 2);
        gap: variables.$inlineSpacing;

        &:not(.p-disabled):hover {
            border-color: variables.$inputHoverBorderColor;
        }

        &:not(.p-disabled).p-focus {
            @include mixins.focused-input();
        }

        .p-chips-token {
            padding: math.div(list.nth(variables.$inputPadding, 1), 2) list.nth(variables.$inputPadding, 2);
            background: variables.$highlightBg;
            color: variables.$highlightTextColor;
            border-radius: variables.$borderRadius;

            .p-chips-token-icon {
                margin-left: variables.$inlineSpacing;
            }
        }

        .p-chips-input-token {
            padding: math.div(list.nth(variables.$inputPadding, 1), 2) 0;

            input {
                font-family: variables.$fontFamily;
                font-size: variables.$fontSize;
                color: variables.$textColor;
                padding: 0;
                margin: 0;
            }
        }
    }
}

p-chips.ng-touched.ng-invalid > .p-chips > .p-inputtext {
    @include mixins.invalid-input();
}

p-chips.p-chips-clearable {
    .p-inputtext {
        padding-right: list.nth(variables.$inputPadding, 2) + variables.$primeIconFontSize;
    }

    .p-chips-clear-icon {
        color: variables.$inputIconColor;
        right: list.nth(variables.$inputPadding, 2);
    }
}
