@use '../../../variables';

.p-terminal {
    background: variables.$panelContentBg;
    color: variables.$panelContentTextColor;
    border: variables.$panelContentBorder;
    padding: variables.$panelContentPadding;

    .p-terminal-input {
        font-size: variables.$fontSize;
        font-family: variables.$fontFamily;
    }
}
