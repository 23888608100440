@use '../../../variables';

@use 'sass:math';

.p-speeddial-button {
    &.p-button.p-button-icon-only {
        width: variables.$speedDialButtonWidth;
        height: variables.$speedDialButtonHeight;

        .p-button-icon {
            font-size: variables.$speedDialButtonIconFontSize;
        }

        .p-icon {
            width: variables.$speedDialButtonIconFontSize;
            height: variables.$speedDialButtonIconFontSize;
        }
    }
}

.p-speeddial-action {
    width: variables.$speedDialActionWidth;
    height: variables.$speedDialActionHeight;
    background: variables.$speedDialActionBg;
    color: variables.$speedDialActionTextColor;

    &:hover {
        background: variables.$speedDialActionHoverBg;
        color: variables.$speedDialActionTextHoverColor;
    }
}

.p-speeddial-direction-up {
    .p-speeddial-item {
        margin: math.div(variables.$inlineSpacing, 2) 0;

        &:first-child {
            margin-bottom: variables.$inlineSpacing;
        }
    }
}

.p-speeddial-direction-down {
    .p-speeddial-item {
        margin: math.div(variables.$inlineSpacing, 2) 0;

        &:first-child {
            margin-top: variables.$inlineSpacing;
        }
    }
}

.p-speeddial-direction-left {
    .p-speeddial-item {
        margin: 0 math.div(variables.$inlineSpacing, 2);

        &:first-child {
            margin-right: variables.$inlineSpacing;
        }
    }
}

.p-speeddial-direction-right {
    .p-speeddial-item {
        margin: 0 math.div(variables.$inlineSpacing, 2);

        &:first-child {
            margin-left: variables.$inlineSpacing;
        }
    }
}

.p-speeddial-circle,
.p-speeddial-semi-circle,
.p-speeddial-quarter-circle {
    .p-speeddial-item {
        margin: 0;

        &:first-child,
        &:last-child {
            margin: 0;
        }
    }
}

.p-speeddial-mask {
    background-color: variables.$maskBg;
}
