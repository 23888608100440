@use 'variables' as *;
@use 'sc-fonts' as *;

.p-accordion {
    p-accordiontab {
        .p-accordion-tab {
            box-shadow: none;
            border: $scCardBorder;
            border-bottom: none;

            &.p-accordion-tab-active {
                margin-bottom: 0;
            }

            &:first-child,
            &:last-child {
                border-radius: 0;
            }

            .p-accordion-header .p-accordion-header-link {
                border-radius: 0;

                &:hover {
                    text-decoration: none;
                }

                .p-accordion-header-text {
                    @extend .title4Font;
                }
            }

            &.p-accordion-tab-active .p-accordion-header {

                .p-accordion-header-text,
                .p-accordion-toggle-icon {
                    color: $primaryColor;
                }
            }
        }

        &:last-child {
            .p-accordion-tab {
                border-bottom: $scCardBorder;
            }
        }
    }
}