@use '../../../variables';
@use '../../mixins';

.p-rating {
    gap: variables.$inlineSpacing;

    .p-rating-icon {
        color: variables.$ratingStarIconOffColor;
        margin-left: variables.$inlineSpacing;
        transition: variables.$formElementTransition;
        font-size: variables.$ratingIconFontSize;

        &.p-icon {
            width: variables.$ratingIconFontSize;
            height: variables.$ratingIconFontSize;
        }

        &.p-rating-cancel {
            color: variables.$ratingCancelIconColor;
        }

        &:focus {
            @include mixins.focused();
        }

        &:first-child {
            margin-left: 0;
        }

        &.p-rating-icon-active {
            color: variables.$ratingStarIconOnColor;
        }
    }

    &:not(.p-disabled):not(.p-readonly) {
        .p-rating-icon {
            &:hover {
                color: variables.$ratingStarIconHoverColor;
            }

            &.p-rating-cancel {
                &:hover {
                    color: variables.$ratingCancelIconHoverColor;
                }
            }
        }
    }
}

@if (variables.$highlightBg == variables.$ratingStarIconOnColor) {
    .p-highlight {
        .p-rating {
            .p-rating-icon {
                color: variables.$highlightTextColor;
            }
        }
    }
}
