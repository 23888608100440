@use '../variables';

.p-breadcrumb {
    .p-menuitem-link {
        &:focus {
            background: variables.$emphasis-lower;
        }

        padding: 0.25rem 0.5rem;
    }
}
