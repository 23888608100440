@use 'sc-colors' as *;
@use 'sc-fonts' as *;

p-tabmenu {
    .p-tabmenu {
        ul.p-tabmenu-nav {
            background: transparent;

            li.p-tabmenuitem {
                // replacing p-tabmenu-ink-bar with border and negative margin
                margin-bottom: -1px;
                border-bottom: 2px transparent solid;

                &.p-highlight {
                    border-bottom-color: $redColor;
                }

                a.p-menuitem-link {
                    height: 60px;
                    background: transparent !important;
                    padding-top: 1.5rem;
                    padding-bottom: 0.5rem;
                    color: $blackColor;

                    span {
                        margin: 0 auto;
                        @extend .subtitle3Font;
                    }
                }
            }

            // p-tabmenu-ink-bar does not work with flex menu items
            .p-tabmenu-ink-bar {
                display: none;
            }
        }

        &.sc-flex-tab-menu {

            //resolve the issue of displaying the scroll button for desktop
            ul {
                width: calc(100% - 15px);
            }

            li.p-tabmenuitem {
                flex-grow: 1;
            }
        }

        .p-tabmenu-nav-btn {
            background: none;
            color: $redColor;
            box-shadow: none;

            .pi {
                font-size: 1.5rem;
            }
        }
    }
}
