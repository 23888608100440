p-calendar.p-inputwrapper-filled {
    .p-inputtext {
        text-align: center;
    }
}

.p-datepicker {
    p-footer {
        .p-button {
            min-width: 4rem;
        }
    }
}
