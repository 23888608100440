@use 'sc-colors' as *;

.p-dialog {
    .p-dialog-header {
        background-color: $grey4Color;
    }

    &.sc-confirm-dialog {
        .p-dialog-header {
            background-color: $whiteColor;
            padding-top: 0.9rem;
            padding-bottom: 0.9rem;
        }

        .p-dialog-content {
            padding-bottom: 4.2rem;
        }
    }
}
