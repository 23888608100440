.cm-project-status-completed {
    color: #129870;
}

.cm-project-status-in-work {
    color: #ec6c0f;
}

.cm-project-status-returned {
    color: #ec9c0f;
}

.cm-project-status-canceled {
    color: #bb171a;
}
