@use 'variables' as *;
@use 'sc-colors' as color;

.p-button {
    border-radius: $borderRadius;
    white-space: nowrap;
    min-width: 12.5rem;
    height: $inputHeight;
    justify-content: center;
    font-weight: bold;

    &.p-button-link {
        padding-left: 0;
        padding-right: 0;
        min-width: auto;
        width: auto;
    }

    // p-button-rounded is used for "icon on circle background" (not real buttons)
    &.p-button-rounded {
        &.sc-grey {
            background-color: color.$grey4Color;
            color: color.$blackColor;
        }

        &.sc-green {
            background-color: color.$greenColor;
            color: color.$whiteColor;
        }

        &.sc-disabled {
            background-color: color.$grey4Color;
            color: color.$grey1Color;
            cursor: default;
        }

        &.p-button-sm {
            height: 32px;
            width: 32px;
            padding: 0;

            span {
                font-size: 0.7rem;
            }
        }
    }

    &.p-button-icon-only {
        &.sc-small-icon-button {
            $smallButtonSize: 2rem;

            width: $smallButtonSize;
            height: $smallButtonSize;
        }
    }

    &.p-button-icon-only,
    &.p-button-text {
        color: color.$blackColor;
    }

    &.sc-red-icon {
        .p-button-icon {
            color: color.$primaryColor;
        }
    }

    .p-button-label {
        flex-grow: 0;
    }
}