@use 'sass:list';
@use '../../../variables';
@use '../../mixins';

.p-listbox {
    background: variables.$inputListBg;
    color: variables.$inputListTextColor;
    border: variables.$inputListBorder;
    border-radius: variables.$borderRadius;

    .p-listbox-header {
        padding: variables.$inputListHeaderPadding;
        border-bottom: variables.$inputListHeaderBorder;
        color: variables.$inputListHeaderTextColor;
        background: variables.$inputListHeaderBg;
        margin: variables.$inputListHeaderMargin;
        border-top-right-radius: variables.$borderRadius;
        border-top-left-radius: variables.$borderRadius;

        .p-listbox-filter {
            padding-right: list.nth(variables.$inputPadding, 2) + variables.$primeIconFontSize;
        }

        .p-listbox-filter-icon {
            right: list.nth(variables.$inputPadding, 2);
            color: variables.$inputIconColor;
        }

        .p-checkbox {
            margin-right: variables.$inlineSpacing;
        }
    }

    .p-listbox-list {
        padding: variables.$inputListPadding;

        .p-listbox-item {
            margin: variables.$inputListItemMargin;
            padding: variables.$inputListItemPadding;
            border: variables.$inputListItemBorder;
            color: variables.$inputListItemTextColor;
            transition: variables.$listItemTransition;
            border-radius: variables.$inputListItemBorderRadius;

            &.p-highlight {
                color: variables.$highlightTextColor;
                background: variables.$highlightBg;
            }

            &:focus {
                @include mixins.focused-listitem();
            }

            .p-checkbox {
                margin-right: variables.$inlineSpacing;
            }
        }

        .p-listbox-item-group {
            margin: variables.$submenuHeaderMargin;
            padding: variables.$submenuHeaderPadding;
            color: variables.$submenuHeaderTextColor;
            background: variables.$submenuHeaderBg;
            font-weight: variables.$submenuHeaderFontWeight;
        }

        .p-listbox-empty-message {
            padding: variables.$inputListItemPadding;
            color: variables.$inputListItemTextColor;
            background: variables.$inputListItemBg;
        }
    }

    &:not(.p-disabled) {
        .p-listbox-item {
            &:not(.p-highlight):not(.p-disabled):hover {
                color: variables.$inputListItemTextHoverColor;
                background: variables.$inputListItemHoverBg;
            }
        }
    }
}

p-listbox.ng-touched.ng-invalid > .p-listbox {
    @include mixins.invalid-input();
}
