@use '../../../variables';

.p-paginator {
    background: variables.$paginatorBg;
    color: variables.$paginatorTextColor;
    border: variables.$paginatorBorder;
    border-width: variables.$paginatorBorderWidth;
    padding: variables.$paginatorPadding;
    border-radius: variables.$borderRadius;

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
        background-color: variables.$paginatorElementBg;
        border: variables.$paginatorElementBorder;
        color: variables.$paginatorElementIconColor;
        min-width: variables.$paginatorElementWidth;
        height: variables.$paginatorElementHeight;
        margin: variables.$paginatorElementMargin;
        transition: variables.$listItemTransition;
        border-radius: variables.$paginatorElementBorderRadius;

        &:not(.p-disabled):not(.p-highlight):hover {
            background: variables.$paginatorElementHoverBg;
            border-color: variables.$paginatorElementHoverBorderColor;
            color: variables.$paginatorElementIconHoverColor;
        }
    }

    .p-paginator-first {
        border-top-left-radius: variables.$paginatorElementBorderRadius;
        border-bottom-left-radius: variables.$paginatorElementBorderRadius;
    }

    .p-paginator-last {
        border-top-right-radius: variables.$paginatorElementBorderRadius;
        border-bottom-right-radius: variables.$paginatorElementBorderRadius;
    }

    .p-dropdown {
        margin-left: variables.$inlineSpacing;
        margin-right: variables.$inlineSpacing;
        height: variables.$paginatorElementHeight;

        .p-dropdown-label {
            padding-right: 0;
        }
    }

    .p-paginator-page-input {
        margin-left: variables.$inlineSpacing;
        margin-right: variables.$inlineSpacing;

        .p-inputtext {
            max-width: variables.$paginatorElementWidth;
        }
    }

    .p-paginator-current {
        background-color: variables.$paginatorElementBg;
        border: variables.$paginatorElementBorder;
        color: variables.$paginatorElementIconColor;
        min-width: variables.$paginatorElementWidth;
        height: variables.$paginatorElementHeight;
        margin: variables.$paginatorElementMargin;
        padding: 0 variables.$inlineSpacing;
    }

    .p-paginator-pages {
        .p-paginator-page {
            background-color: variables.$paginatorElementBg;
            border: variables.$paginatorElementBorder;
            color: variables.$paginatorElementIconColor;
            min-width: variables.$paginatorElementWidth;
            height: variables.$paginatorElementHeight;
            margin: variables.$paginatorElementMargin;
            transition: variables.$listItemTransition;
            border-radius: variables.$paginatorElementBorderRadius;

            &.p-highlight {
                background: variables.$highlightBg;
                border-color: variables.$highlightBg;
                color: variables.$highlightTextColor;
            }

            &:not(.p-highlight):hover {
                background: variables.$paginatorElementHoverBg;
                border-color: variables.$paginatorElementHoverBorderColor;
                color: variables.$paginatorElementIconHoverColor;
            }
        }
    }
}
