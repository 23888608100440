@use 'sass:color';
@use '../../../variables';
@use '../../mixins';

.p-organizationchart {
    .p-organizationchart-node-content {
        &.p-organizationchart-selectable-node:not(.p-highlight):hover {
            background: variables.$inputListItemHoverBg;
            color: variables.$inputListItemTextHoverColor;
        }

        &.p-highlight {
            background: variables.$highlightBg;
            color: variables.$highlightTextColor;

            .p-node-toggler {
                i {
                    color: color.adjust(variables.$highlightBg, $lightness: -25%);
                }
            }
        }
    }

    .p-organizationchart-line-down {
        background: variables.$organizationChartConnectorColor;
    }

    .p-organizationchart-line-left {
        border-right: variables.$panelContentBorder;
        border-color: variables.$organizationChartConnectorColor;
    }

    .p-organizationchart-line-top {
        border-top: variables.$panelContentBorder;
        border-color: variables.$organizationChartConnectorColor;
    }

    .p-organizationchart-node-content {
        border: variables.$panelContentBorder;
        background: variables.$panelContentBg;
        color: variables.$panelContentTextColor;
        padding: variables.$panelContentPadding;
    }

    .p-organizationchart-node-content .p-node-toggler {
        background: inherit;
        color: inherit;
        border-radius: 50%;

        &:focus {
            @include mixins.focused();
        }
    }
}
