@use 'sass:list';
@use '../../../variables';
@use '../../mixins';

@use 'sass:math';

.p-treeselect {
    background: variables.$inputBg;
    border: variables.$inputBorder;
    transition: variables.$formElementTransition;
    border-radius: variables.$borderRadius;

    &:not(.p-disabled):hover {
        border-color: variables.$inputHoverBorderColor;
    }

    &:not(.p-disabled).p-focus {
        @include mixins.focused-input();
    }

    .p-treeselect-label {
        padding: variables.$inputPadding;
        transition: variables.$formElementTransition;

        &.p-placeholder {
            color: variables.$inputPlaceholderTextColor;
        }
    }

    &.p-treeselect-chip {
        .p-treeselect-token {
            padding: math.div(list.nth(variables.$inputPadding, 1), 2) list.nth(variables.$inputPadding, 2);
            margin-right: variables.$inlineSpacing;
            background: variables.$chipBg;
            color: variables.$chipTextColor;
            border-radius: variables.$chipBorderRadius;
        }
    }

    .p-treeselect-trigger {
        background: transparent;
        color: variables.$inputIconColor;
        width: variables.$inputGroupAddOnMinWidth;
        border-top-right-radius: variables.$borderRadius;
        border-bottom-right-radius: variables.$borderRadius;
    }
}

p-treeselect.ng-invalid.ng-touched > .p-treeselect {
    @include mixins.invalid-input();
}

.p-inputwrapper-filled {
    .p-treeselect {
        &.p-treeselect-chip {
            .p-treeselect-label {
                padding: math.div(list.nth(variables.$inputPadding, 1), 2) list.nth(variables.$inputPadding, 2);
            }
        }
    }
}

.p-treeselect-panel {
    background: variables.$inputOverlayBg;
    color: variables.$inputListTextColor;
    border: variables.$inputOverlayBorder;
    border-radius: variables.$borderRadius;
    box-shadow: variables.$inputOverlayShadow;

    .p-treeselect-header {
        padding: variables.$inputListHeaderPadding;
        border-bottom: variables.$inputListHeaderBorder;
        color: variables.$inputListHeaderTextColor;
        background: variables.$inputOverlayHeaderBg;
        margin: variables.$inputListHeaderMargin;
        border-top-right-radius: variables.$borderRadius;
        border-top-left-radius: variables.$borderRadius;

        .p-treeselect-filter-container {
            margin-right: variables.$inlineSpacing;

            .p-treeselect-filter {
                padding-right: list.nth(variables.$inputPadding, 2) + variables.$primeIconFontSize;
            }

            .p-treeselect-filter-icon {
                right: list.nth(variables.$inputPadding, 2);
                color: variables.$inputIconColor;
            }

            &.p-treeselect-clearable-filter {
                .p-treeselect-filter {
                    padding-right: 2 * (list.nth(variables.$inputPadding, 2) + variables.$primeIconFontSize);
                }

                .p-treeselect-filter-clear-icon {
                    right: (2 * list.nth(variables.$inputPadding, 2)) + variables.$primeIconFontSize;
                }
            }
        }

        .p-treeselect-close {
            @include mixins.action-icon();
        }
    }

    .p-treeselect-items-wrapper {
        .p-tree {
            border: 0 none;
        }

        .p-treeselect-empty-message {
            padding: variables.$inputListItemPadding;
            color: variables.$inputListItemTextColor;
            background: variables.$inputListItemBg;
        }
    }
}

.p-input-filled {
    .p-treeselect {
        background: variables.$inputFilledBg;

        &:not(.p-disabled):hover {
            background-color: variables.$inputFilledHoverBg;
        }

        &:not(.p-disabled).p-focus {
            background-color: variables.$inputFilledFocusBg;
        }
    }
}

p-treeselect.p-treeselect-clearable {
    .p-treeselect-label-container {
        padding-right: list.nth(variables.$inputPadding, 2) + variables.$primeIconFontSize;
    }

    .p-treeselect-clear-icon {
        color: variables.$inputIconColor;
        right: variables.$buttonIconOnlyWidth;
    }
}
