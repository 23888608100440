@use '../../../variables';
@use '../../mixins';

.p-selectbutton {
    .p-button {
        background: variables.$toggleButtonBg;
        border: variables.$toggleButtonBorder;
        color: variables.$toggleButtonTextColor;
        transition: variables.$formElementTransition;

        .p-button-icon-left,
        .p-button-icon-right {
            color: variables.$toggleButtonIconColor;
        }

        &:not(.p-disabled):not(.p-highlight):hover {
            background: variables.$toggleButtonHoverBg;
            border-color: variables.$toggleButtonHoverBorderColor;
            color: variables.$toggleButtonTextHoverColor;

            .p-button-icon-left,
            .p-button-icon-right {
                color: variables.$toggleButtonIconHoverColor;
            }
        }

        &.p-highlight {
            background: variables.$toggleButtonActiveBg;
            border-color: variables.$toggleButtonActiveBorderColor;
            color: variables.$toggleButtonTextActiveColor;

            .p-button-icon-left,
            .p-button-icon-right {
                color: variables.$toggleButtonIconActiveColor;
            }

            &:hover {
                background: variables.$toggleButtonActiveHoverBg;
                border-color: variables.$toggleButtonActiveHoverBorderColor;
                color: variables.$toggleButtonTextActiveHoverColor;

                .p-button-icon-left,
                .p-button-icon-right {
                    color: variables.$toggleButtonIconActiveHoverColor;
                }
            }
        }
    }
}

p-selectbutton.ng-touched.ng-invalid > .p-selectbutton > .p-button {
    @include mixins.invalid-input();
}
