@use '../../../variables';

.p-galleria {
    .p-galleria-close {
        margin: variables.$galleriaCloseIconMargin;
        background: variables.$galleriaCloseIconBg;
        color: variables.$galleriaCloseIconColor;
        width: variables.$galleriaCloseIconWidth;
        height: variables.$galleriaCloseIconHeight;
        transition: variables.$actionIconTransition;
        border-radius: variables.$galleriaCloseIconBorderRadius;

        .p-galleria-close-icon {
            font-size: variables.$galleriaCloseIconFontSize;
        }

        .p-icon-wrapper {
            .p-icon {
                width: variables.$galleriaCloseIconFontSize;
                height: variables.$galleriaCloseIconFontSize;
            }
        }

        &:hover {
            background: variables.$galleriaCloseIconHoverBg;
            color: variables.$galleriaCloseIconHoverColor;
        }
    }

    .p-galleria-item-nav {
        background: variables.$galleriaItemNavigatorBg;
        color: variables.$galleriaItemNavigatorColor;
        width: variables.$galleriaItemNavigatorWidth;
        height: variables.$galleriaItemNavigatorHeight;
        transition: variables.$actionIconTransition;
        border-radius: variables.$galleriaItemNavigatorBorderRadius;
        margin: variables.$galleriaItemNavigatorMargin;

        .p-galleria-item-prev-icon,
        .p-galleria-item-next-icon {
            font-size: variables.$galleriaItemNavigatorFontSize;
        }

        .p-icon-wrapper {
            .p-icon {
                width: variables.$galleriaCloseIconFontSize;
                height: variables.$galleriaCloseIconFontSize;
            }
        }

        &:not(.p-disabled) {
            &:hover {
                background: variables.$galleriaItemNavigatorHoverBg;
                color: variables.$galleriaItemNavigatorHoverColor;
            }
        }
    }

    .p-galleria-caption {
        background: variables.$galleriaCaptionBg;
        color: variables.$galleriaCaptionTextColor;
        padding: variables.$galleriaCaptionPadding;
    }

    .p-galleria-indicators {
        padding: variables.$galleriaIndicatorsPadding;

        .p-galleria-indicator {
            button {
                background-color: variables.$galleriaIndicatorBg;
                width: variables.$galleriaIndicatorWidth;
                height: variables.$galleriaIndicatorHeight;
                transition: variables.$actionIconTransition;
                border-radius: variables.$galleriaIndicatorBorderRadius;

                &:hover {
                    background: variables.$galleriaIndicatorHoverBg;
                }
            }

            &.p-highlight {
                button {
                    background: variables.$highlightBg;
                    color: variables.$highlightTextColor;
                }
            }
        }
    }

    &.p-galleria-indicators-bottom,
    &.p-galleria-indicators-top {
        .p-galleria-indicator {
            margin-right: variables.$inlineSpacing;
        }
    }

    &.p-galleria-indicators-left,
    &.p-galleria-indicators-right {
        .p-galleria-indicator {
            margin-bottom: variables.$inlineSpacing;
        }
    }

    &.p-galleria-indicator-onitem {
        .p-galleria-indicators {
            background: variables.$galleriaIndicatorsBgOnItem;

            .p-galleria-indicator {
                button {
                    background: variables.$galleriaIndicatorBgOnItem;

                    &:hover {
                        background: variables.$galleriaIndicatorHoverBgOnItem;
                    }
                }

                &.p-highlight {
                    button {
                        background: variables.$highlightBg;
                        color: variables.$highlightTextColor;
                    }
                }
            }
        }
    }

    .p-galleria-thumbnail-container {
        background: variables.$galleriaThumbnailContainerBg;
        padding: variables.$galleriaThumbnailContainerPadding;

        .p-galleria-thumbnail-prev,
        .p-galleria-thumbnail-next {
            margin: variables.$inlineSpacing;
            background-color: variables.$galleriaThumbnailNavigatorBg;
            color: variables.$galleriaThumbnailNavigatorColor;
            width: variables.$galleriaThumbnailNavigatorWidth;
            height: variables.$galleriaThumbnailNavigatorHeight;
            transition: variables.$actionIconTransition;
            border-radius: variables.$galleriaThumbnailNavigatorBorderRadius;

            &:hover {
                background: variables.$galleriaThumbnailNavigatorHoverBg;
                color: variables.$galleriaThumbnailNavigatorHoverColor;
            }
        }
    }
}

.p-galleria-mask {
    --maskbg: #{variables.$galleriaMaskBg};
}
