@use '../../../variables';

.p-card {
    background: variables.$panelContentBg;
    color: variables.$panelContentTextColor;
    box-shadow: variables.$cardShadow;
    border-radius: variables.$borderRadius;

    .p-card-body {
        padding: variables.$cardBodyPadding;
    }

    .p-card-title {
        font-size: variables.$cardTitleFontSize;
        font-weight: variables.$cardTitleFontWeight;
        margin-bottom: variables.$inlineSpacing;
    }

    .p-card-subtitle {
        font-weight: variables.$cardSubTitleFontWeight;
        margin-bottom: variables.$inlineSpacing;
        color: variables.$cardSubTitleColor;
    }

    .p-card-content {
        padding: variables.$cardContentPadding;
    }

    .p-card-footer {
        padding: variables.$cardFooterPadding;
    }
}
