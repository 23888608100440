@use 'sc-fonts' as *;
@use 'sc-colors' as *;

p-message {
    .p-inline-message {
        @extend .subtitle3Font;
    }
}

.sc-required-input:after {
    content: '*';
    margin-left: 1px;
    color: $redColor;
}
