@use '../../../variables';
@use '../../mixins';

.p-tabmenu {
    .p-tabmenu-nav {
        background: variables.$tabviewNavBg;
        border: variables.$tabviewNavBorder;
        border-width: variables.$tabviewNavBorderWidth;

        .p-menuitem-badge {
            @include mixins.menuitem-badge();
        }

        .p-tabmenuitem {
            margin-right: variables.$tabviewHeaderSpacing;

            .p-menuitem-link {
                border: variables.$tabviewHeaderBorder;
                border-width: variables.$tabviewHeaderBorderWidth;
                border-color: variables.$tabviewHeaderBorderColor;
                background: variables.$tabviewHeaderBg;
                color: variables.$tabviewHeaderTextColor;
                padding: variables.$tabviewHeaderPadding;
                font-weight: variables.$tabviewHeaderFontWeight;
                border-top-right-radius: variables.$borderRadius;
                border-top-left-radius: variables.$borderRadius;
                transition: variables.$listItemTransition;
                margin: variables.$tabviewHeaderMargin;

                .p-menuitem-icon {
                    margin-right: variables.$inlineSpacing;
                }

                &:not(.p-disabled):focus {
                    @include mixins.focused-inset();
                }
            }

            &:not(.p-highlight):not(.p-disabled):hover {
                .p-menuitem-link {
                    background: variables.$tabviewHeaderHoverBg;
                    border-color: variables.$tabviewHeaderHoverBorderColor;
                    color: variables.$tabviewHeaderTextHoverColor;
                }
            }

            &.p-highlight {
                .p-menuitem-link {
                    background: variables.$tabviewHeaderActiveBg;
                    border-color: variables.$tabviewHeaderActiveBorderColor;
                    color: variables.$tabviewHeaderTextActiveColor;
                }
            }
        }
    }

    .p-tabmenu-left-icon {
        margin-right: variables.$inlineSpacing;
    }

    .p-tabmenu-right-icon {
        margin-left: variables.$inlineSpacing;
    }

    .p-tabmenu-nav-btn.p-link {
        background: variables.$tabviewHeaderActiveBg;
        color: variables.$tabviewHeaderTextActiveColor;
        width: variables.$buttonIconOnlyWidth;
        box-shadow: variables.$raisedButtonShadow;
        border-radius: 0;

        &:focus {
            @include mixins.focused-inset();
        }
    }
}
