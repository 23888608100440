@use '../../../variables';
@use '../../mixins';

.fc {
    /* FullCalendar 4 */
    &.fc-unthemed {
        .fc-view-container {
            th {
                background: variables.$panelHeaderBg;
                border: variables.$panelHeaderBorder;
                color: variables.$panelHeaderTextColor;
            }

            td {
                &.fc-widget-content {
                    background: variables.$panelContentBg;
                    border: variables.$panelContentBorder;
                    color: variables.$panelContentTextColor;
                }

                &.fc-head-container {
                    border: variables.$panelHeaderBorder;
                }
            }

            .fc-row {
                border-right: variables.$panelHeaderBorder;
            }

            .fc-event {
                background: variables.$fullCalendarEventBg;
                border: variables.$fullCalendarEventBorder;
                color: variables.$fullCalendarEventTextColor;
            }

            .fc-divider {
                background: variables.$panelHeaderBg;
                border: variables.$panelHeaderBorder;
            }
        }

        .fc-toolbar {
            .fc-button {
                color: variables.$buttonTextColor;
                background: variables.$buttonBg;
                border: variables.$buttonBorder;
                font-size: variables.$fontSize;
                transition: variables.$formElementTransition;
                border-radius: variables.$borderRadius;
                display: flex;
                align-items: center;

                &:enabled:hover {
                    background: variables.$buttonHoverBg;
                    color: variables.$buttonTextHoverColor;
                    border-color: variables.$buttonHoverBorderColor;
                }

                &:enabled:active {
                    background: variables.$buttonActiveBg;
                    color: variables.$buttonTextActiveColor;
                    border-color: variables.$buttonActiveBorderColor;

                    &:focus {
                        @include mixins.focused();
                    }
                }

                .fc-icon-chevron-left {
                    font-family: 'PrimeIcons' !important;
                    text-indent: 0;
                    @include mixins.icon_override('\e900');
                    font-size: variables.$primeIconFontSize;
                }

                .fc-icon-chevron-right {
                    font-family: 'PrimeIcons' !important;
                    text-indent: 0;
                    @include mixins.icon_override('\e901');
                    font-size: variables.$primeIconFontSize;
                }

                &:focus {
                    @include mixins.focused();
                }

                &.fc-dayGridMonth-button,
                &.fc-timeGridWeek-button,
                &.fc-timeGridDay-button {
                    background: variables.$toggleButtonBg;
                    border: variables.$toggleButtonBorder;
                    color: variables.$toggleButtonTextColor;
                    transition: variables.$formElementTransition;

                    &:hover {
                        background: variables.$toggleButtonHoverBg;
                        border-color: variables.$toggleButtonHoverBorderColor;
                        color: variables.$toggleButtonTextHoverColor;
                    }

                    &.fc-button-active {
                        background: variables.$toggleButtonActiveBg;
                        border-color: variables.$toggleButtonActiveBorderColor;
                        color: variables.$toggleButtonTextActiveColor;

                        &:hover {
                            background: variables.$toggleButtonActiveHoverBg;
                            border-color: variables.$toggleButtonActiveHoverBorderColor;
                            color: variables.$toggleButtonTextActiveHoverColor;
                        }
                    }

                    &:focus {
                        @include mixins.focused();
                        z-index: 1;
                    }
                }
            }

            .fc-button-group {
                .fc-button {
                    border-radius: 0;

                    &:first-child {
                        border-top-left-radius: variables.$borderRadius;
                        border-bottom-left-radius: variables.$borderRadius;
                    }

                    &:last-child {
                        border-top-right-radius: variables.$borderRadius;
                        border-bottom-right-radius: variables.$borderRadius;
                    }
                }
            }
        }
    }

    /* FullCalendar 5 */
    &.fc-theme-standard {
        .fc-view-harness {
            .fc-scrollgrid {
                border-color: variables.$panelContentBorderColor;
            }

            th {
                background: variables.$panelHeaderBg;
                border-color: variables.$panelHeaderBorderColor;
                color: variables.$panelHeaderTextColor;
            }

            td {
                color: variables.$panelContentTextColor;
                border-color: variables.$panelContentBorderColor;
            }

            .fc-view {
                background: variables.$panelContentBg;
            }

            .fc-popover {
                background: none;
                border: 0 none;

                .fc-popover-header {
                    border: variables.$panelHeaderBorder;
                    padding: variables.$panelHeaderPadding;
                    background: variables.$panelHeaderBg;
                    color: variables.$panelHeaderTextColor;

                    .fc-popover-close {
                        opacity: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        font-family: 'PrimeIcons' !important;
                        @include mixins.icon_override('\e90b');
                        font-size: variables.$primeIconFontSize;
                        @include mixins.action-icon(false);
                    }
                }

                .fc-popover-body {
                    padding: variables.$panelContentPadding;
                    border: variables.$panelContentBorder;
                    background: variables.$panelContentBg;
                    color: variables.$panelContentTextColor;
                    border-top: 0 none;
                }
            }

            .fc-event {
                &.fc-daygrid-block-event {
                    color: variables.$fullCalendarEventTextColor;
                    background: variables.$fullCalendarEventBg;
                    border-color: variables.$fullCalendarEventBorderColor;

                    .fc-event-main {
                        color: variables.$fullCalendarEventTextColor;
                    }
                }

                &.fc-daygrid-dot-event {
                    .fc-daygrid-event-dot {
                        background: variables.$fullCalendarEventBg;
                        border-color: variables.$fullCalendarEventBorderColor;
                    }

                    &:hover {
                        background: variables.$inputListItemHoverBg;
                        color: variables.$inputListItemTextHoverColor;
                    }
                }
            }

            .fc-cell-shaded {
                background: variables.$panelHeaderBg;
            }
        }

        .fc-toolbar {
            .fc-button {
                color: variables.$buttonTextColor;
                background: variables.$buttonBg;
                border: variables.$buttonBorder;
                font-size: variables.$fontSize;
                transition: variables.$formElementTransition;
                border-radius: variables.$borderRadius;

                &:enabled:hover {
                    background: variables.$buttonHoverBg;
                    color: variables.$buttonTextHoverColor;
                    border-color: variables.$buttonHoverBorderColor;
                }

                &:enabled:active {
                    background: variables.$buttonActiveBg;
                    color: variables.$buttonTextActiveColor;
                    border-color: variables.$buttonActiveBorderColor;

                    &:focus {
                        @include mixins.focused();
                    }
                }

                &:disabled {
                    opacity: variables.$disabledOpacity;
                    color: variables.$buttonTextColor;
                    background: variables.$buttonBg;
                    border: variables.$buttonBorder;
                }

                .fc-icon-chevron-left {
                    font-family: 'PrimeIcons' !important;
                    text-indent: 0;
                    @include mixins.icon_override('\e900');
                    font-size: variables.$primeIconFontSize;
                }

                .fc-icon-chevron-right {
                    font-family: 'PrimeIcons' !important;
                    text-indent: 0;
                    @include mixins.icon_override('\e901');
                    font-size: variables.$primeIconFontSize;
                }

                &:focus {
                    @include mixins.focused();
                }

                &.fc-dayGridMonth-button,
                &.fc-timeGridWeek-button,
                &.fc-timeGridDay-button {
                    background: variables.$toggleButtonBg;
                    border: variables.$toggleButtonBorder;
                    color: variables.$toggleButtonTextColor;
                    transition: variables.$formElementTransition;

                    &:hover {
                        background: variables.$toggleButtonHoverBg;
                        border-color: variables.$toggleButtonHoverBorderColor;
                        color: variables.$toggleButtonTextHoverColor;
                    }

                    &.fc-button-active {
                        background: variables.$toggleButtonActiveBg;
                        border-color: variables.$toggleButtonActiveBorderColor;
                        color: variables.$toggleButtonTextActiveColor;

                        &:hover {
                            background: variables.$toggleButtonActiveHoverBg;
                            border-color: variables.$toggleButtonActiveHoverBorderColor;
                            color: variables.$toggleButtonTextActiveHoverColor;
                        }
                    }

                    &:not(:disabled) {
                        &:focus {
                            @include mixins.focused();
                            z-index: 1;
                        }
                    }
                }
            }

            .fc-button-group {
                .fc-button {
                    border-radius: 0;

                    &:first-child {
                        border-top-left-radius: variables.$borderRadius;
                        border-bottom-left-radius: variables.$borderRadius;
                    }

                    &:last-child {
                        border-top-right-radius: variables.$borderRadius;
                        border-bottom-right-radius: variables.$borderRadius;
                    }
                }
            }
        }

        a {
            color: variables.$textColor;
        }

        .fc-highlight {
            color: variables.$highlightTextColor;
            background: variables.$highlightBg;
        }
    }
}
