@use '../variables';

.p-listbox {
    .p-listbox-list {
        .p-listbox-item {
            .p-ink {
                background-color: rgba(variables.$primaryColor, 0.16);
            }

            &:focus {
                background: variables.$emphasis-lower;

                &.p-highlight {
                    background: rgba(variables.$primaryColor, 0.24);
                }
            }
        }
    }
}
