@use 'sc-fonts' as *;

.p-inputtext {
    &:enabled:focus {
        box-shadow: none;

        &.ng-invalid.ng-dirty {
            box-shadow: none;
        }
    }
}

.p-inputtextarea {
    @extend .body2Font;
    resize: none;
}
