@use '../../../variables';

.p-editor-container {
    .p-editor-toolbar {
        background: variables.$editorToolbarBg;
        border-top-right-radius: variables.$borderRadius;
        border-top-left-radius: variables.$borderRadius;

        &.ql-snow {
            border: variables.$editorToolbarBorder;

            .ql-stroke {
                stroke: variables.$editorToolbarIconColor;
            }

            .ql-fill {
                fill: variables.$editorToolbarIconColor;
            }

            .ql-picker {
                .ql-picker-label {
                    border: 0 none;
                    color: variables.$editorToolbarIconColor;

                    &:hover {
                        color: variables.$editorToolbarIconHoverColor;

                        .ql-stroke {
                            stroke: variables.$editorToolbarIconHoverColor;
                        }

                        .ql-fill {
                            fill: variables.$editorToolbarIconHoverColor;
                        }
                    }
                }

                &.ql-expanded {
                    .ql-picker-label {
                        color: variables.$editorToolbarIconHoverColor;

                        .ql-stroke {
                            stroke: variables.$editorToolbarIconHoverColor;
                        }

                        .ql-fill {
                            fill: variables.$editorToolbarIconHoverColor;
                        }
                    }

                    .ql-picker-options {
                        background: variables.$inputOverlayBg;
                        border: variables.$inputOverlayBorder;
                        box-shadow: variables.$inputOverlayShadow;
                        border-radius: variables.$borderRadius;
                        padding: variables.$inputListPadding;

                        .ql-picker-item {
                            color: variables.$inputListItemTextColor;

                            &:hover {
                                color: variables.$inputListItemTextHoverColor;
                                background: variables.$inputListItemHoverBg;
                            }
                        }
                    }

                    &:not(.ql-icon-picker) {
                        .ql-picker-item {
                            padding: variables.$inputListItemPadding;
                        }
                    }
                }
            }
        }
    }

    .p-editor-content {
        border-bottom-right-radius: variables.$borderRadius;
        border-bottom-left-radius: variables.$borderRadius;

        &.ql-snow {
            border: variables.$editorContentBorder;
        }

        .ql-editor {
            background: variables.$inputBg;
            color: variables.$inputTextColor;
            border-bottom-right-radius: variables.$borderRadius;
            border-bottom-left-radius: variables.$borderRadius;
        }
    }

    .ql-snow.ql-toolbar button:hover,
    .ql-snow.ql-toolbar button:focus {
        color: variables.$editorToolbarIconHoverColor;

        .ql-stroke {
            stroke: variables.$editorToolbarIconHoverColor;
        }

        .ql-fill {
            fill: variables.$editorToolbarIconHoverColor;
        }
    }

    .ql-snow.ql-toolbar button.ql-active,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
        color: variables.$editorIconActiveColor;

        .ql-stroke {
            stroke: variables.$editorIconActiveColor;
        }

        .ql-fill {
            fill: variables.$editorIconActiveColor;
        }

        .ql-picker-label {
            color: variables.$editorIconActiveColor;
        }
    }
}
