@use '../../../variables';
@use '../../mixins';

.p-panel {
    .p-panel-header {
        border: variables.$panelHeaderBorder;
        padding: variables.$panelHeaderPadding;
        background: variables.$panelHeaderBg;
        color: variables.$panelHeaderTextColor;
        border-top-right-radius: variables.$borderRadius;
        border-top-left-radius: variables.$borderRadius;

        .p-panel-title {
            font-weight: variables.$panelHeaderFontWeight;
        }

        .p-panel-header-icon {
            @include mixins.action-icon();
        }
    }

    &.p-panel-toggleable {
        .p-panel-header {
            padding: variables.$panelToggleableHeaderPadding;
        }
    }

    .p-panel-content {
        padding: variables.$panelContentPadding;
        border: variables.$panelContentBorder;
        background: variables.$panelContentBg;
        color: variables.$panelContentTextColor;
        border-bottom-right-radius: variables.$borderRadius;
        border-bottom-left-radius: variables.$borderRadius;
        border-top: 0 none;
    }

    .p-panel-footer {
        padding: variables.$panelFooterPadding;
        border: variables.$panelFooterBorder;
        background: variables.$panelFooterBg;
        color: variables.$panelFooterTextColor;
        border-top: 0 none;
    }

    .p-panel-icons-end {
        order: 2;
        margin-left: auto;
    }

    .p-panel-icons-start {
        order: 0;
        margin-right: variables.$inlineSpacing;
    }

    .p-panel-icons-center {
        order: 2;
        width: 100%;
        text-align: center;
    }
}
