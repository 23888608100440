@use 'sc-colors' as *;

.p-splitbutton {
    &.sc-split-button-outlined {
        .p-menu {
            .p-menuitem:hover {
                .p-menuitem-text {
                    font-weight: bold;
                    color: $redColor;
                }
            }
        }

        .p-button {
            background: transparent;
            color: $redColor;

            border-color: $redColor;
            border-style: solid;

            $borderRadius: 2px;
            $borderWidth: 1px;

            &:hover {
                background-color: $redLightColor;
            }

            &.p-splitbutton-defaultbutton {
                border-top-left-radius: $borderRadius;
                border-bottom-left-radius: $borderRadius;
                border-width: $borderWidth 0 $borderWidth $borderWidth;
            }

            &.p-splitbutton-menubutton {
                border-top-right-radius: $borderRadius;
                border-bottom-right-radius: $borderRadius;
                border-width: $borderWidth;
            }
        }
    }
}
