@use '../variables';

.p-tree {
    .p-tree-container {
        .p-treenode {
            .p-treenode-content {
                &:focus {
                    background: variables.$emphasis-lower;

                    &.p-highlight {
                        background: rgba(variables.$primaryColor, 0.24);
                    }
                }
            }
        }
    }
}
