@use '../../../variables';
@use '../../mixins';

.p-megamenu {
    padding: variables.$horizontalMenuPadding;
    background: variables.$horizontalMenuBg;
    color: variables.$horizontalMenuTextColor;
    border: variables.$horizontalMenuBorder;
    border-radius: variables.$borderRadius;

    .p-megamenu-root-list {
        > .p-menuitem {
            > .p-menuitem-link {
                @include mixins.horizontal-rootmenuitem-link();
            }

            &.p-menuitem-active {
                > .p-menuitem-link,
                > .p-menuitem-link:not(.p-disabled):hover {
                    background: variables.$horizontalMenuRootMenuitemActiveBg;

                    .p-menuitem-text {
                        color: variables.$horizontalMenuRootMenuitemTextActiveColor;
                    }

                    .p-menuitem-icon {
                        color: variables.$horizontalMenuRootMenuitemIconActiveColor;
                    }

                    .p-submenu-icon {
                        color: variables.$horizontalMenuRootMenuitemIconActiveColor;
                    }
                }
            }
        }
    }

    .p-menuitem-link {
        @include mixins.menuitem-link();
    }

    .p-megamenu-panel {
        background: variables.$overlayMenuBg;
        color: variables.$menuTextColor;
        border: variables.$overlayMenuBorder;
        box-shadow: variables.$overlayMenuShadow;
    }

    .p-megamenu-submenu-header {
        margin: variables.$submenuHeaderMargin;
        padding: variables.$submenuHeaderPadding;
        color: variables.$submenuHeaderTextColor;
        background: variables.$submenuHeaderBg;
        font-weight: variables.$submenuHeaderFontWeight;
        border-top-right-radius: variables.$borderRadius;
        border-top-left-radius: variables.$borderRadius;
    }

    .p-megamenu-submenu {
        padding: variables.$verticalMenuPadding;
        width: variables.$menuWidth;

        .p-menu-separator {
            border-top: variables.$divider;
            margin: variables.$menuSeparatorMargin;
        }

        .p-menuitem {
            margin: variables.$verticalMenuitemMargin;

            &:last-child {
                margin: 0;
            }
        }
    }

    .p-menuitem {
        &.p-menuitem-active {
            > .p-menuitem-link {
                background: variables.$menuitemActiveBg;

                .p-menuitem-text {
                    color: variables.$menuitemTextActiveColor;
                }

                .p-menuitem-icon,
                .p-submenu-icon {
                    color: variables.$menuitemIconActiveColor;
                }
            }
        }
    }

    &.p-megamenu-vertical {
        width: variables.$menuWidth;
        padding: variables.$verticalMenuPadding;

        .p-menuitem {
            margin: variables.$verticalMenuitemMargin;

            &:last-child {
                margin: 0;
            }
        }
    }

    .p-menuitem-badge {
        @include mixins.menuitem-badge();
    }
}
