@use '../../../variables';
@use '../../mixins';

.p-tieredmenu {
    padding: variables.$verticalMenuPadding;
    background: variables.$menuBg;
    color: variables.$menuTextColor;
    border: variables.$menuBorder;
    border-radius: variables.$borderRadius;
    width: variables.$menuWidth;

    .p-menuitem-link {
        @include mixins.menuitem-link();
    }

    &.p-tieredmenu-overlay {
        background: variables.$overlayMenuBg;
        border: variables.$overlayMenuBorder;
        box-shadow: variables.$overlayMenuShadow;
    }

    .p-submenu-list {
        padding: variables.$verticalMenuPadding;
        background: variables.$overlayMenuBg;
        border: variables.$overlayMenuBorder;
        box-shadow: variables.$overlayMenuShadow;
    }

    .p-menuitem {
        margin: variables.$verticalMenuitemMargin;

        &:last-child {
            margin: 0;
        }

        &.p-menuitem-active {
            > .p-menuitem-link {
                background: variables.$menuitemActiveBg;

                .p-menuitem-text {
                    color: variables.$menuitemTextActiveColor;
                }

                .p-menuitem-icon,
                .p-submenu-icon {
                    color: variables.$menuitemIconActiveColor;
                }
            }
        }
    }

    .p-menu-separator {
        border-top: variables.$divider;
        margin: variables.$menuSeparatorMargin;
    }

    .p-submenu-icon {
        font-size: variables.$menuitemSubmenuIconFontSize;
    }

    .p-icon {
        width: variables.$menuitemSubmenuIconFontSize;
        height: variables.$menuitemSubmenuIconFontSize;
    }

    .p-menuitem-badge {
        @include mixins.menuitem-badge();
    }
}
