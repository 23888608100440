@use '../variables';

.p-paginator {
    justify-content: flex-end;

    .p-paginator-element {
        &:focus {
            background: variables.$emphasis-lower;

            &.p-highlight {
                background: rgba(variables.$primaryColor, 0.24);
            }
        }
    }
}
