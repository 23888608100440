@use 'sass:math';

@use 'variables' as *;
@use 'sc-colors' as color;

i,
span,
.p-tieredmenu .p-menuitem .p-menuitem-icon {
    &.far {
        &.fa-file-word {
            color: color.$blueColor;
        }

        &.fa-file-excel {
            color: color.$greenDarkColor;
        }

        &.fa-file-pdf {
            color: color.$redColor;
        }

        &.fa-file-archive {
            color: color.$greenColor;
        }

        &.fa-file-image,
        &.fa-file {
            color: color.$yellowColor;
        }
    }
}

.fas {
    &.fa-link {
        color: color.$yellowColor;
    }
}

@for $i from 1 through 6 {
    i.sc-rounded-outlined-icon-#{$i} {
        border-radius: 100%;

        font-size: $scFontSizeValue * $i * (1 - $i * 0.1) * 1px;

        $sizeValue: $scFontSizeValue * $i * 1.8 * 1px;
        $paddingValue: math.div($scFontSizeValue * $i, 2.2) * 1px;

        width: $sizeValue;
        height: $sizeValue;
        padding-top: $paddingValue;
        text-align: center;

        &.sc-grey {
            background-color: color.$grey4Color;
            color: color.$blackColor;
        }
    }
}