@use '../variables';

.p-megamenu {
    .p-menuitem {
        .p-menuitem-link {
            &:focus {
                background: variables.$emphasis-lower;
            }
        }
    }
}
