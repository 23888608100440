@use 'sass:list';
@use '../../../variables';
@use '../../mixins';

@use 'sass:math';

.p-cascadeselect {
    background: variables.$inputBg;
    border: variables.$inputBorder;
    transition: variables.$formElementTransition;
    border-radius: variables.$borderRadius;

    &:not(.p-disabled):hover {
        border-color: variables.$inputHoverBorderColor;
    }

    &:not(.p-disabled).p-focus {
        @include mixins.focused-input();
    }

    .p-cascadeselect-label {
        background: transparent;
        border: 0 none;
        padding: variables.$inputPadding;

        &.p-placeholder {
            color: variables.$inputPlaceholderTextColor;
        }

        &:enabled:focus {
            outline: 0 none;
            box-shadow: none;
        }
    }

    .p-cascadeselect-trigger {
        background: transparent;
        color: variables.$inputIconColor;
        width: variables.$inputGroupAddOnMinWidth;
        border-top-right-radius: variables.$borderRadius;
        border-bottom-right-radius: variables.$borderRadius;
    }
}

.p-cascadeselect-panel {
    background: variables.$inputOverlayBg;
    color: variables.$inputListTextColor;
    border: variables.$inputOverlayBorder;
    border-radius: variables.$borderRadius;
    box-shadow: variables.$inputOverlayShadow;

    .p-cascadeselect-items {
        padding: variables.$inputListPadding;

        .p-cascadeselect-item {
            margin: variables.$inputListItemMargin;
            border: variables.$inputListItemBorder;
            color: variables.$inputListItemTextColor;
            background: variables.$inputListItemBg;
            transition: variables.$listItemTransition;
            border-radius: variables.$inputListItemBorderRadius;

            .p-cascadeselect-item-content {
                padding: variables.$inputListItemPadding;

                &:focus {
                    @include mixins.focused-listitem();
                }
            }

            &.p-highlight {
                color: variables.$highlightTextColor;
                background: variables.$highlightBg;
            }

            &:not(.p-highlight):not(.p-disabled):hover {
                color: variables.$inputListItemTextHoverColor;
                background: variables.$inputListItemHoverBg;
            }

            .p-cascadeselect-group-icon {
                font-size: variables.$menuitemSubmenuIconFontSize;
            }

            .p-icon {
                width: variables.$menuitemSubmenuIconFontSize;
                height: variables.$menuitemSubmenuIconFontSize;
            }
        }
    }
}

p-cascadeselect.ng-touched.ng-invalid > .p-cascadeselect {
    @include mixins.invalid-input();
}

.p-input-filled {
    .p-cascadeselect {
        background: variables.$inputFilledBg;

        &:not(.p-disabled):hover {
            background-color: variables.$inputFilledHoverBg;
        }

        &:not(.p-disabled).p-focus {
            background-color: variables.$inputFilledFocusBg;
        }
    }
}

p-cascadeselect.p-cascadeselect-clearable {
    .p-cascadeselect-label {
        padding-right: list.nth(variables.$inputPadding, 2);
    }

    .p-cascadeselect-clear-icon {
        color: variables.$inputIconColor;
        right: variables.$inputGroupAddOnMinWidth;
    }
}

.p-overlay-modal {
    .p-cascadeselect-sublist {
        .p-cascadeselect-panel {
            box-shadow: none;
            border-radius: 0;
            padding: math.div(variables.$inlineSpacing, 2) 0 math.div(variables.$inlineSpacing, 2)
                variables.$inlineSpacing;
        }
    }

    .p-cascadeselect-item-active > .p-cascadeselect-item-content .p-cascadeselect-group-icon {
        transform: rotate(90deg);
    }
}
